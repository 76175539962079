import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export const BASE_URL = 'https://app-workflow-trigger-ib38.onrender.com'
// export const BASE_URL = 'http://localhost:10000'
const axiosService = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

const nonAuthService = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosService.interceptors.request.use(async (config) => {
    if (config.noAuth) {
        return config
    }

    const { token } = JSON.parse(localStorage.getItem('token'))
    config.headers.Authorization = 'Bearer ' + token;
    console.log('[Request]', config.baseURL + config.url, JSON.stringify(token));
    return config;
});

axiosService.interceptors.response.use(
    (res) => {
        // @ts-ignore
        console.debug('[Response]', res.config.baseURL + res.config.url, res.status, res.data);
        return Promise.resolve(res);
    },
    (err) => {
        console.debug(
            '[Response]',
            err.config.baseURL + err.config.url,
            err.response.status,
            err.response.data
        );
        return Promise.reject(err);
    }
);

// @ts-ignore
const refreshAuthLogic = async (failedRequest) => {
    const { refresh } = JSON.parse(localStorage.getItem('token'))
    if (refresh !== null) {
        return axios
            .post(
                `${BASE_URL}/token/refresh/`,
                {
                    refresh,
                },
                {
                    baseURL: process.env.REACT_APP_API_URL
                }
            )
            .then((resp) => {
                const { access } = resp.data;
                failedRequest.response.config.headers.Authorization = 'Bearer ' + access;
                localStorage.setItem('token', JSON.stringify(resp.data))
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {}
            });
    }
};

createAuthRefreshInterceptor(axiosService, refreshAuthLogic);

export function fetcher(url, noAuth, options) {
    options = options ? options : {}
    if (noAuth) {
        options.noAuth = true
    }

    return axiosService.get(url, {
        ...options,
    }).then((res) => res.data);
}

export function post(url, data) {
    return axiosService.post(url, data).then((res) => res.data);
}

export function deleter(url, data) {
    return axiosService.delete(url, data).then((res) => res.data);
}

export function patcher(url, data) {
    return axiosService.patch(url, data).then((res) => res.data);
}

export default {
    axiosService,
    nonAuthService,
};
