import {useCallback, useEffect, useMemo, useState} from "react";
import {Flex, Heading, Spinner, Stack, useColorModeValue} from "@chakra-ui/react";
import {useParams} from "react-router-dom";
import {Places} from "../util/places";
import {TrackingMode} from "../constants";
import axios from "axios";
import {BASE_URL} from "../util/requests/axios";
import {trackingFrequencyToSeconds} from "../util/time";

export const CarrierLocationCallback = () => {
    const [coords, setCoords] = useState(null)
    const {loadId} = useParams();
    const placesClient = useMemo(() => new Places(), [])
    const [error, setError] = useState(null)
    useEffect(async () => {
        const positionCallback = async (position) => {
            fetch(`${BASE_URL}/unauth/tracking_records`, {
                method: 'POST',
                headers: new Headers({'content-type': 'application/json'}),
                body: JSON.stringify({
                    load_id: Number(loadId),
                    location: {
                        lat: position.coords.latitude,
                        long: position.coords.longitude,
                        name: await placesClient.reverseGeocodeLocationName(position.coords.latitude, position.coords.longitude),
                    },
                    recorded_at: new Date().toISOString(),
                    notes: "-",
                    mode: TrackingMode.TEXT.toServer(),
                })
            }).then((result) => result.json().then((res) => console.log(res))).finally(() => setCoords(position.coords))
        }

        navigator.geolocation.getCurrentPosition(positionCallback, setError)
        const res = await axios.get(`${BASE_URL}/tracking_frequency/${loadId}`)
        const frequency = trackingFrequencyToSeconds(res.data)
        setInterval(() => {
            navigator.geolocation.getCurrentPosition(positionCallback)
        }, frequency);
    }, [loadId,placesClient]);
    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            >
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    {
                        error ? (<Heading fontSize={'xl'}>
                            Please enable device location and reload this page. If you continue to get this error, please contact support@livetrucks.io
                        </Heading>) : (coords ? (<Heading fontSize={'3xl'}>Successfully registered location</Heading>) : <Spinner />) }
                </Stack>
            </Stack>
        </Flex>
    )
}