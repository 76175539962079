import {Container, Spinner} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {fetcher} from "../util/requests/axios";
import {Load, TrackingRecord} from "../models";
import {RenderedShipment} from "../broker/shipment-details";
import {unAuthFetcher} from "../util/unauth";

export const SharedShipmentView = () => {
    const [shipment, setShipment] = useState(null)
    const [locations, setLocations] = useState([])
    let params = useParams();
    const {id} = params;

    async function fetchShipmentAndTracking() {
        let shipment = await unAuthFetcher(`/unauth/sharing_links/${id}`, true)
        shipment = new Load(shipment.load)
        const locations = await fetcher(`/unauth/tracking_records/${id}?page_size=100&page=1`, true)
        const mappedRecords = locations.records.map((loc) => new TrackingRecord(loc))
        setLocations(shipment.trackingRecords.reverse())
        setShipment(shipment)
    }

    useEffect(() => {
        fetchShipmentAndTracking()
    }, [id])
    return (
        <Container py={6} maxW={{base: '95vw', lg: '70vw'}} alignContent={"center"}>
            {!shipment ? <Spinner/> :
                <RenderedShipment shipment={shipment} locations={locations} refreshShipment={fetchShipmentAndTracking} showActions={false}/>
            }
        </Container>
    )
}
