import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export const BASE_URL = 'https://app-workflow-trigger-ib38.onrender.com'
const axiosService = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

axiosService.interceptors.request.use(async (config) => {
    if (config.skipAuth) {
        return config
    }
    const { token } = JSON.parse(localStorage.getItem('carrier-token'))
    console.log(token)
    config.headers.Authorization = 'Bearer ' + token;
    console.log('[Request]', config.baseURL + config.url, JSON.stringify(token));
    return config;
});

axiosService.interceptors.response.use(
    (res) => {
        // @ts-ignore
        console.debug('[Response]', res.config.baseURL + res.config.url, res.status, res.data);
        return Promise.resolve(res);
    },
    (err) => {
        console.debug(
            '[Response]',
            err.config.baseURL + err.config.url,
            err.response.status,
            err.response.data
        );
        return Promise.reject(err);
    }
);

export function fetcherWithoutData(url, noAuth) {
    return axiosService.get(url, {
        skipAuth: noAuth,
    })
}

export function fetcher(url, noAuth) {
    return axiosService.get(url, {
        skipAuth: noAuth,
    }).then((res) => res.data);
}

export function post(url, data, skipAuth) {
    return axiosService.post(url, data, {skipAuth}).then((res) => res.data);
}

export function deleter(url, data) {
    return axiosService.delete(url, data).then((res) => res.data);
}

export function patcher(url, data) {
    return axiosService.patch(url, data).then((res) => res.data);
}

export default {
    axiosService,
};
