import {ShipmentMap} from "./load-map";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay, Input, FormErrorMessage, Select, VStack, Tabs, TabList, Tab, TabPanels, TabPanel, Tag,
} from '@chakra-ui/react';
import {
    Box,
    Button,
    Center,
    Container, Divider,
    Flex, FormControl, FormLabel,
    Heading,
    HStack, Link,
    List,
    ListIcon,
    ListItem,
    Menu,
    MenuButton, MenuItem, MenuList,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Spinner,
    Stack, Switch,
    Text,
    useColorModeValue,
    useDisclosure, useToast
} from "@chakra-ui/react";
import {CheckIcon, ChevronDownIcon} from "@chakra-ui/icons";
import {BiPhoneIncoming, BiPhoneOutgoing, MdCallMade, MdCheckCircle, MdPhoneIphone, MdTextsms} from "react-icons/all";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useParams, useOutletContext, useNavigate} from "react-router-dom";
import {Load, TrackingRecord} from "../models";
import {Places} from "../util/places";
import {BASE_URL, deleter, fetcher, patcher, post} from "../util/requests/axios";
import {TrackingMode} from "../constants";
import {Field, Form, Formik} from "formik";
import amplitude from "amplitude-js";
import {EditShipmentModal} from "./edit-shipment-modal";
import {ShipmentDashboardStepBar} from "./index";
import fileDownload from "js-file-download";
import Pagination from "@choc-ui/paginator";
const EXTERNAL_LINK_FORMAT = "https://livetrucks.io/share/shipments"
export const DetailField = (props) => {
    const {fieldName, fieldValue} = props;
    return (
        <Stack justifyContent={'start'}>
            <Heading
                color={useColorModeValue('gray.700', 'white')}
                fontSize={'l'}
                fontFamily={'body'}>
                {fieldName}
            </Heading>
            <Stack>
            {fieldValue.map((field) => {
                return <Text>
                    {field}
                </Text>
            })}
            </Stack>
        </Stack>
    )
}

export const LoadSharingModal = (props) => {
    const { shipment, isOpen, onClose } = props;
    const [sharingLink, setSharingLink] = useState(null)
    useEffect(() => {
        fetcher(`/active_sharing_link/${shipment.id}`).then((link) => setSharingLink(link.ID))
    }, [shipment])

    const toggleShareable = async () => {
        if (sharingLink) {
            await deleter(`/sharing_links/${sharingLink}`, {
                'id': sharingLink,
                'enabled': false,
                'load_id': shipment.id,
            })
            setSharingLink(null)
        }
        else setShareable()
    }

    const setShareable = useCallback(() => {
        post('/sharing_links', {
            'load_id': shipment.id,
            'enabled': true,
        }).then((res) => setSharingLink(res.ID))
    }, [shipment])

    const externalLink = sharingLink ? `${EXTERNAL_LINK_FORMAT}/${sharingLink}`: null;
    return <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader color={useColorModeValue('black', 'black')}>Sharing settings</ModalHeader>
            <ModalCloseButton color={useColorModeValue('black', 'black')}/>
            <Divider />
            <ModalBody color={useColorModeValue('black', 'gray.700')} marginTop="10px">
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='email-alerts' mb='0' color={useColorModeValue('black', 'white')}>
                        Share shipment with link?
                    </FormLabel>
                    <Switch isChecked={sharingLink != null} onChange={() => toggleShareable()} id='share-email' />
                </FormControl>
                {externalLink &&
                    <HStack marginTop="40px" >
                    <Heading size={"m"}>
                        Link:
                    </Heading>
                    <Link href={externalLink} isExternal>{externalLink}</Link>
                    </HStack>}
            </ModalBody>
        </ModalContent>
    </Modal>
            }

const getIcon = (location) => {
    switch(location.mode.value) {
        case TrackingMode.TEXT.value:
            return MdTextsms
        case TrackingMode.APP.value:
            return MdPhoneIphone
        case TrackingMode.OUTBOUND_CALL.value:
            return BiPhoneOutgoing
        case TrackingMode.INBOUND_CALL.value:
            return BiPhoneIncoming
        default:
            return BiPhoneOutgoing
    }
}

export const TrackingInfo = (props) => {
    const {shipment, locations, showActions} = props;


    return (
        <Box
            w={'full'}
            boxShadow={'lg'}
            rounded={'md'}
            overflow={'hidden'}>
            <HStack padding="20px" justifyContent={'space-between'}>
            <Heading
                color={useColorModeValue('black', 'green.400')}
                fontSize={'xl'}
                >
                Tracking Details
            </Heading>
                {showActions && <TrackingActionsMenu {...props} />}
            </HStack>
            <Tabs>
                <TabList>
                    <Tab>Tracking History</Tab>
                    <Tab><Text>Onboarding History</Text><Tag>Coming soon</Tag></Tab>
                    <Tab>Carrier Info</Tab>
                </TabList>
            <Divider />
            <Box
                bg={useColorModeValue('gray.50', 'gray.700')}
               padding="20px"
            >
                    <TabPanels>
                        <TabPanel>
                            <TrackingRecords locations={locations} shipment={shipment}/>
                        </TabPanel>
                        <TabPanel >
                        </TabPanel>
                        <TabPanel>
                            <CarrierInfo shipment={shipment}/>
                        </TabPanel>
                    </TabPanels>

            </Box>
            </Tabs>
        </Box>
            );
}

const TrackingRecords = (props) => {
    const {shipment} = props;
    const [locations, setLocations] = useState(props.locations);
    const [current, setCurrent] = React.useState(1);
    const [isLoading, setIsLoading] = React.useState(true)
    const [totalRecords, setTotalRecords] = React.useState(0)
    const onChange = async (current) => {
            const locations = await fetcher(`unauth/tracking_records/${shipment.id}?page_size=20&page=${current}`, true)
            setLocations(locations.records.map((loc) => new TrackingRecord(loc)))
            setTotalRecords(locations.total)
    }

    useEffect(() => {
        onChange(1)
    }, [])

    if (locations == null || locations.length == 0) {
        return null
    }

    return <Stack w={"100%"}>
        <List spacing={5} >
        {locations.map((location) => {
                return( <ListItem>
                    <HStack>
                        <ListIcon as={getIcon(location)} color='black.500' />
                        <Stack>
                            <Text>
                                {location.location.name}
                            </Text>
                            <Text>
                                {location.recordedAt.toLocaleString()}
                            </Text>
                            <Text>
                                {location.note}
                            </Text>
                        </Stack>
                    </HStack>
                </ListItem>)
                    ;
            })}
    </List>
        <Pagination
            current={current}
            onChange={(page) => {
                setCurrent(page)
                onChange(page)
            }}
            pageSize={20}
            total={totalRecords}
            paginationProps={{ display: "flex", mb: 5, alignSelf: "center" }}
            pageNeighbours={1}
            responsive={{ activePage: true, pageSize: true }}
        />
    </Stack>
}

const AddManualEntryModal = (props) => {
    const {shipment, isOpen, onClose, onOpen, refreshShipment} = props;
    return (<Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader color={useColorModeValue('black', 'black')}>Manually record tracking location</ModalHeader>
            <ModalCloseButton color={useColorModeValue('black', 'black')}/>
            <Divider />
            <ModalBody color={useColorModeValue('black', 'gray.700')} marginTop="10px">
                <Formik
                    initialValues={{ name: '', lat: 0, long: 0, method: TrackingMode.APP.value, recorded_at: ''}}
                    onSubmit={async (values, actions) => {
                        await post('unauth/tracking_records', {
                            load_id: Number(shipment.id),
                            location: {
                                lat: Number(values.lat),
                                long: Number(values.long),
                                name: values.name,
                            },
                            recorded_at: new Date(values.recorded_at).toISOString(),
                            notes: "-",
                            mode: Number(values.method),
                        })
                        actions.setSubmitting(false)
                        onClose()
                        refreshShipment()
                    }}
                >
                    {(props) => (
                        <Form id="signup-form">
                            <Stack spacing={5} >
                                <Field name='name' >
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.name && form.touched.name}>
                                            <FormLabel htmlFor='name'>Location name</FormLabel>
                                            <Input {...field} id='name' placeholder='Las Vegas' />
                                            <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name='lat'>
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.lat && form.touched.lat}>
                                            <FormLabel htmlFor='lat'>Latitude</FormLabel>
                                            <Input {...field} id='lat' />
                                            <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name='long'>
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.long && form.touched.long}>
                                            <FormLabel htmlFor='long'>Longitude</FormLabel>
                                            <Input {...field} id='long' />
                                            <FormErrorMessage>{form.errors.long}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name='method' as="select">
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.method && form.touched.method}>
                                            <FormLabel htmlFor='method'>Method</FormLabel>
                                            <Select {...field} id='password' >
                                                <option value={TrackingMode.APP.toServer()}>App</option>
                                                <option value={TrackingMode.TEXT.toServer()}>Text</option>
                                                <option value={TrackingMode.OUTBOUND_CALL.toServer()}>Manual call</option>
                                                <option value={TrackingMode.INBOUND_CALL.toServer()}>Inbound call</option>
                                            </Select>
                                            <FormErrorMessage>{form.errors.method}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name='recorded_at'>
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.recorded_at && form.touched.recorded_at}>
                                            <FormLabel htmlFor='recorded_at'>Recorded at</FormLabel>
                                            <Input type={'datetime-local'}{...field} id='password' />
                                            <FormErrorMessage>{form.errors.recorded_at}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Button
                                    mt={4}
                                    colorScheme='teal'
                                    isLoading={props.isSubmitting}
                                    type='submit'
                                >
                                    Submit
                                </Button>
                            </Stack>
                        </Form>

                    )}
                </Formik>
            </ModalBody>
        </ModalContent>
    </Modal>);
}

export const TrackingActionsMenu = (props) => {
    const toast = useToast()
    const {shipment, refreshShipment} = props;
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isManualEntryModalOpen, onOpen: onManualEntryModalOpen, onClose:onManualEntryModalClose } = useDisclosure()
    const cancelRef = useRef()

    const triggerText = () => {
        post(`/trigger_tracking_text/${props.shipment.id}`).then(() => {
            toast({
                title: 'Tracking sms triggered',
                description: "We've requested the carrier for latest location",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        })
    }
    const toggleTracking = () => {
        patcher(`tracking_config`, {
                  ...shipment.trackingJob,
                 'enabled': !shipment.trackingJob.enabled,
            }
        ).then(() => refreshShipment())
    }

    return (
        <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Actions
            </MenuButton>
            <MenuList>
                <MenuItem onClick={onOpen}>Trigger tracking text</MenuItem>
                <MenuItem onClick={toggleTracking}>{shipment.trackingJob.enabled ? 'Pause tracking' : 'Unpause tracking'}</MenuItem>
                <MenuItem onClick={onManualEntryModalOpen}>Manually add tracking record</MenuItem>
                <MenuItem onClick={() => {
                    fetcher(`/pdf/${shipment.id}`, false,{responseType: 'blob'}).then((response) => {
                        fileDownload(response, `${shipment.loadReference}_tracking_records.pdf`);
                    });
                }}>
                    Download as pdf
                </MenuItem>
            </MenuList>
            <AddManualEntryModal onOpen={onManualEntryModalOpen} onClose={onManualEntryModalClose} isOpen={isManualEntryModalOpen} refreshShipment={refreshShipment} shipment={shipment}/>
            <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Trigger tracking text
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        This will trigger a one time link to driver's phone number prompting them to update their location. Please only use these for ad-hoc requests.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='green' onClick={() => {
                            triggerText();
                            onClose();
                        }} ml={3}>
                            Send text
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        </Menu>
    )
}
export const ShipmentDetailsPage = (props) => {
    const [shipment, setShipment] = useState(null)
    const [locations, setLocations] = useState([])
    let params = useParams();
    const {id} = params;

    async function fetchShipmentAndTracking() {
        const shipment = await fetcher(`/loads/light/${id}`)
        const mappedShipment = new Load(shipment)
        const locations = await fetcher(`/unauth/tracking_records/${id}?page_size=1000&page=1`)
        setShipment(mappedShipment)
        const mappedRecords = locations.records.map((loc) => new TrackingRecord(loc))
        mappedShipment.lastLocation = mappedRecords?.length > 0 ? mappedRecords[0]: null;
        setLocations(mappedRecords)
    }

    useEffect(() => {
        fetchShipmentAndTracking()
    }, [id])

    return (
                    <Container py={6} maxW={{base: '95vw', lg:'70vw'}} alignContent={"center"}>
                        {!shipment ? <Spinner/> :
                            <RenderedShipment shipment={shipment} locations={locations} refreshShipment={fetchShipmentAndTracking} showActions={true}/>
                        }
                    </Container>
    )
}

export const RenderedShipment = (props) => {
    const {shipment, locations, showActions} = props;
    return <Stack spacing={50}>
        <Box
            bg={useColorModeValue('white', 'gray.800')}
            rounded={'md'}
            overflow={'hidden'}>
            <ShipmentMap locations={locations} destination={shipment.destination}
                         origin={shipment.origin} shipment={shipment}/>
        </Box>
        <Box
            w={'full'}
            boxShadow={'xl'}
            rounded={'md'}
            overflow={'hidden'}>
            <HStack padding="20px" justifyContent={'space-between'}>
                <VStack align={"start"}>
            <Heading
                fontSize={'xl'}>
                Shipment details
            </Heading>
                    <Text fontSize={'l'}>
                        {shipment.loadReference}
                    </Text>
                </VStack>
                {showActions && <LoadActions shipment={shipment}/>}
            </HStack>
            <Divider />
            <Stack spacing={5} padding="20px" bg={useColorModeValue('gray.50', 'gray.700')} justifyContent={"space-between"} direction={{base: "column", lg: "row"}}>
                <ShipmentDashboardStepBar load={shipment}/>
                <HStack spacing={2} columns={{base: 2, lg: 4}} textAlign={"left"} width={"80%"} justifyContent={{base: "flex-start", md: "flex-end"}}>
                    {/*<DetailField fieldName="Carrier"*/}
                    {/*             fieldValue={[shipment.carrier.name || '-', shipment.carrier.phone_number]}/>*/}
                    <List>
                        <ListItem style={{
                            paddingLeft: "20px",
                            paddingBottom: "20px",
                            position: "relative",
                        }}
                                  _before={{
                                      borderRadius: "50%",
                                      content: `""`,
                                      position: "absolute",
                                      backgroundColor: "currentColor",
                                      top: "7px",
                                      left: 0,
                                      width: "9px",
                                      height: "9px",
                                  }}
                                  _after={{
                                      content: `""`,
                                      position: "absolute",
                                      backgroundColor: "currentColor",
                                      top: "18px",
                                      left: "4px",
                                      height: "calc(100% - 13px)",
                                      width: "1px",
                                  }}>
                            <VStack align={"start"}>
                                <Text>
                                    {shipment.origin.name}
                                </Text>
                                <Text>
                                    {new Date(shipment.origin.time).toLocaleString()}
                                </Text>
                            </VStack>
                        </ListItem>
                        <ListItem style={{
                            paddingLeft: "20px",
                            position: "relative",
                        }} _before={{
                            content: `""`,
                            position: "absolute",
                            backgroundColor: "currentColor",
                            top: "7px",
                            left: 0,
                            width: "9px",
                            height: "9px",
                        }}>
                            <VStack align={"start"}>
                                <Text>
                                    {shipment.destination.name}
                                </Text>
                                {shipment.destination.time ?
                                    <Text>
                                        {new Date(shipment.destination.time).toLocaleString()}
                                    </Text>: null}
                            </VStack>

                        </ListItem>
                    </List>
                    {/*/!*<DetailField fieldName="Status" fieldValue={[shipment.displayStatus]} />*!/*/}
                    {/*<DetailField fieldName="Pickup" fieldValue={[shipment.origin.name]} />*/}
                    {/*<DetailField fieldName="Destination" fieldValue={[shipment.destination.name]} />*/}
                    {/*<DetailField fieldName="Last tracked location" fieldValue={[shipment.lastLocation?.location?.name || '-']} />*/}
                {/*<DetailField fieldName="Load reference" fieldValue={[shipment.loadReference]}/>*/}
                {/*<DetailField fieldName="Pickup" fieldValue={[shipment.origin.name]}/>*/}
                {/*<DetailField fieldName="Destination" fieldValue={[shipment.destination.name]}/>*/}
                {/*<DetailField fieldName="Carrier"*/}
                {/*             fieldValue={[shipment.carrier.name || '-', shipment.carrier.phone_number]}/>*/}
            </HStack>
            </Stack>
        </Box>
        <Stack direction={{
            base: "column",
            lg: "row",
        }} spacing={5}>
        <TrackingInfo {...props} />
        {/*<CarrierInfo {...props} />*/}
        </Stack>
    </Stack>
}

const CarrierInfo = (props) => {
    const {shipment} = props;
    return <HStack justify="start"  spacing={10}>
        <VStack align={"start"} >
        <Heading size={"sm"} >
            Name
        </Heading>
            <Text>
                {shipment.carrier.name}
            </Text>
        </VStack>
        <VStack align={"start"}>
            <Heading size={"sm"} >
                Phone number
            </Heading>
            <Text>
                {shipment.carrier.phone_number}
            </Text>
        </VStack>
    </HStack>
}

const LoadActions = (props) => {
    const {shipment} = props;
    const navigate = useNavigate()
    const {onOpen: onOpenSharingModal, onClose: onCloseSharingModal, isOpen: isOpenSharingModal} = useDisclosure();
    const {onOpen: onEditLoadOpen, onClose: onEditLoadClose, isOpen: editLoadOpen} = useDisclosure();
    const onDeleteLoad = () => {
        deleter(`${BASE_URL}/loads/${shipment.id}`).then(() => navigate('/broker'))
    }

    return <Menu>
        <LoadSharingModal shipment={shipment} isOpen={isOpenSharingModal} onClose={onCloseSharingModal} />
        <EditShipmentModal shipment={shipment} isOpen={editLoadOpen} onClose={onEditLoadClose} />
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Actions
        </MenuButton>
        <MenuList>
            <MenuItem onClick={() => onOpenSharingModal()}>Sharing settings</MenuItem>
            <MenuItem onClick={() => onDeleteLoad()}>Delete load</MenuItem>
            {/*<MenuItem onClick={() => onEditLoadOpen()}>Edit load</MenuItem>*/}
        </MenuList>
    </Menu>
}
