import {
    Box,
    Button,
    Container,
    Flex, FormControl, FormErrorMessage, FormLabel,
    Heading, HStack, Input, ListItem,
    Radio,
    RadioGroup, Select,
    Stack,
    Text, UnorderedList,
    useColorModeValue, useToast
} from "@chakra-ui/react";
import {Step, Steps, useSteps} from "chakra-ui-steps";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useCarrierAuth} from "../util/auth/carrier-auth";
import {BASE_URL, fetcher, post} from "../util/requests/axios";
import {useBrokerAuth} from "../util/auth/broker-auth";
import amplitude from "amplitude-js";
import {Field, Form, Formik} from "formik";
import roseRocket, {LoginRoseRocket} from "../util/rose-rocket";
const content = (
    <Flex py={4}>
        Hi
    </Flex>
);

const VALUES = {
    ROSE_ROCKET: 1,
    TAI: 2,
}


export const ConnectTMS = () => {
    const [provider, setProvider] = useState(null);

    const getProviderSteps = () => {
        if (provider == VALUES.ROSE_ROCKET) {
            return <RoseRocketIntegration />
        }

        if (provider == VALUES.TAI)  {
            return <TAIIntegration />
        }
    }

    const navig = (value) => {
        setProvider(value)
    }

    return (
        <Flex
            minH={'100vh'}
            paddingTop={10}
            justify={'center'}
        >
            <Stack spacing={8} w={'7xl'} py={10} px={6}>
                <Stack>
                    <Heading fontSize={'3xl'}>Connect your TMS</Heading>
                    <Text>Connect your TMS to auto fetch loads and update tracking records</Text>
                </Stack>
                <Box  rounded={'lg'}
                      boxShadow={'lg'}
                      p={8}>
                    {provider === null ?
                        (
                            <Box>
                                <Heading size={"md"}>
                                    Choose your TMS to get started
                                </Heading>
                                <Stack padding={5} spacing={10}>
                                    <RadioGroup value={provider} onChange={navig}>
                                        <Stack>
                                            <Radio value={VALUES.ROSE_ROCKET}>Rose Rocket</Radio>
                                            <Radio value={2}>ITS Dispatch</Radio>
                                            {/*<Radio value={VALUES.TAI}>TAI</Radio>*/}
                                            <Radio value={VALUES.TAI}>Axon</Radio>
                                                <Radio value={3}>Ascend TMS</Radio>
                                        </Stack>
                                    </RadioGroup>
                                    <Text>
                                        Please reach out to us at support@livetrucks.io if you don't see your TMS provider listed
                                    </Text>
                                </Stack>
                            </Box>) : getProviderSteps()}
                </Box>
            </Stack>
        </Flex>
    );

}


export const RoseRocketIntegration = (props) => {
    const {code} = props;
    const {user} = useBrokerAuth();
    const toast = useToast()
    const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
        initialStep: 0,
    });


    return (
            <Formik
                initialValues={{ email: '', password: ''}}
                onSubmit={async (values, actions) => {
                    try {
                        await post('/tms_connection', {
                            email: values.email,
                            password: values.password,
                        })
                    } catch (e) {
                        actions.setSubmitting(false)
                        return
                    } finally {
                        toast({
                            title: 'TMS Connection was connected',
                            description: "Feel free to close this window",
                            status: 'success',
                            duration: 9000,
                            isClosable: true,
                        })
                        actions.setSubmitting(false)
                    }
                }}
            >
                {(props) => (
                    <Form id="signup-form">
                        <Stack spacing={5} padding="20px">
                            <Field name='email'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.email && form.touched.email}>
                                        <FormLabel htmlFor='email'>Rose rocket email</FormLabel>
                                        <Input {...field} id='email' placeholder='hello@roserocket.com' />
                                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Field name='password'>
                                {({ field, form }) => (
                                    <FormControl isInvalid={form.errors.password && form.touched.password}>
                                        <FormLabel htmlFor='userName'>Rose rocket password</FormLabel>
                                        <Input type={'password'}{...field} id='password' />
                                        <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Button
                                mt={4}
                                colorScheme='teal'
                                isLoading={props.isSubmitting}
                                type='submit'
                            >
                                Submit
                            </Button>
                        </Stack>
                    </Form>

                )}
            </Formik>
    );
}

export const TAIIntegration = (props) => {
    const {code} = props;
    const {user} = useBrokerAuth();
    const toast = useToast()
    const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
        initialStep: 0,
    });


    return (
        <Formik
            initialValues={{ email: '', password: ''}}
            onSubmit={async (values, actions) => {
                try {
                    await amplitude.logEvent('tms', values)
                } catch (e) {
                    actions.setSubmitting(false)
                    return
                } finally {
                    toast({
                        title: 'TMS Connection was connected',
                        description: "Feel free to close this window",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    actions.setSubmitting(false)
                }
            }}
        >
            {(props) => (
                <Form id="signup-form">
                    <Stack spacing={5} padding="20px">

                        <Field name='email'>
                            {({ field, form }) => (
                                <FormControl isInvalid={form.errors.email && form.touched.email}>
                                    <FormLabel htmlFor='email'> Email</FormLabel>
                                    <Input {...field} id='email' placeholder='hello@roserocket.com' />
                                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name='password'>
                            {({ field, form }) => (
                                <FormControl isInvalid={form.errors.password && form.touched.password}>
                                    <FormLabel htmlFor='userName'> Password</FormLabel>
                                    <Input type={'password'}{...field} id='password' />
                                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>
                        <Button
                            mt={4}
                            colorScheme='teal'
                            isLoading={props.isSubmitting}
                            type='submit'
                        >
                            Submit
                        </Button>
                    </Stack>
                </Form>

            )}
        </Formik>
    );
}
