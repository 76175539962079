import * as React from "react";
import {BASE_URL, fetcher} from "../requests/axios";
import {useEffect} from "react";
import {H} from "highlight.run";
import axios from "axios";
import {Navigate, useLocation} from "react-router-dom";
import {useBrokerAuth} from "./broker-auth";

export function RequireAdminAuth({ children }) {
    let auth = useBrokerAuth();
    let location = useLocation();
    if (auth.isFetching){
        return null;
    }

    if (!auth.user || ["tanuj", "support@livetrucks.io"].indexOf(auth.actualUser) == -1) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}
