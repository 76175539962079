import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {BASE_URL} from "./requests/axios";

const nonAuthService = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export function unAuthFetcher(url) {
    return nonAuthService.get(url).then((res) => res.data)
}

export default nonAuthService