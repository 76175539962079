import {
    Alert, AlertIcon,
    Box,
    Button,
    Divider,
    FormControl, FormErrorMessage, FormLabel,
    Heading, HStack,
    Input, InputGroup, InputLeftAddon, InputRightAddon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Select, Stack, Text, useColorModeValue
} from "@chakra-ui/react";
import {useContext, useMemo, useRef, useState} from "react";
import {post} from "../util/requests/axios";
import {useBrokerAuth} from "../util/auth/broker-auth";
import {AsyncSelect} from "chakra-react-select";
import {forwardGeocodeName, getTimezone, Places} from "../util/places";
import {useDebouncedCallback} from "use-debounce";
import {debounce} from "lodash";
import {Field, Form, Formik, useFormik} from "formik";
import moment from "moment-timezone";
import mom from "moment";
import tz from "tz-lookup";
import {Autocomplete, StandaloneSearchBox, useJsApiLoader} from "@react-google-maps/api";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {usePlacesWidget} from "react-google-autocomplete";


const LOAD_FORM_FIELDS = {
    'load-id': {
        display: 'Load ID',
    },
}

const PICKUP_FORM_FIELDS = {
    'location': {
        display:'Location'
    },
    'date': {
        display: 'Date'
    },
}

const DROPOFF_FORM_FIELDS = {
    'location': {
        display:'Location'
    },
    'date': {
        display: 'Date',
        optional: true,
    },
}

const DRIVER_FORM_FIELDS = {
    'name': {
        display:'Driver name'
    },
    'email': {
        display: 'Driver email',
        optional: true,
    },
    'phone-number': {
        display: 'Driver phone number',
    },
}

const TRACKING_FORM_FIELDS = {
    'frequency': {
        display: 'Tracking frequency',
    },
}

export const LocationSearch = (props) => {
    const inputRef = useRef(null)
    const { ref } = usePlacesWidget({
        apiKey: "AIzaSyCfvyrd_c5wb9BWXN8T99RwnvOZposRM-M",
        onPlaceSelected: (place) => {
            //@ts-ignore
            inputRef.current.setValue(place?.formatted_address);
        },
    });

    const customStyles = {
        container: provided => ({
            ...provided,
            minWidth: '300px',
        })
    };
    const searchBoxRef = useRef(null)

    const {setLocation, pastLocations, defaultValue} = props;

    const onBoxLoad = (loaded) => {
        searchBoxRef.current = loaded
    }

    console.log(searchBoxRef)
    const textChange = inputValue => {
        setLocation(inputValue.value)
    };

    const pastLocationOptions = useMemo(() =>
        pastLocations.map((location) => ({
            value: location,
            "label": location.name,
        })), [pastLocations])

    return (
        <Box minW={'350px'} color={'black'}>
            <Input
                ref={(c) => {
                    inputRef.current = c;
                    if (c) ref.current = c.input;
                }}
                size="large"
            />
        </Box>
    )
}

const locationToServer = (loc) => ({
    lat: loc.geometry.coordinates[1],
    long: loc.geometry.coordinates[0],
    name: loc.place_name,
})

const FrequencySelect = (props) => {
    const {actualID, fields, handleInputChange} = props;
    return (
        <Select placeholder='Select frequency' id={actualID} value={fields[actualID]}  onChange={handleInputChange} maxWidth='100%'>
            <option value='hours'>Hours</option>
        </Select>
    )
}



export const FIELD_IDS = {
    LOAD_ID: 'load-id',
    PICKUP_TIME: 'pickup-time',
    PICKUP_TZ: 'pickup-tz',
    PICKUP_LOCATION: 'pickup-location',
    DROPOFF_TIME: 'dropoff-time',
    DROPOFF_TZ: 'dropoff-tz',
    DROPOFF_LOCATION: 'dropoff-location',
    DRIVER_NAME: 'driver-name',
    DRIVER_PHONE_NUMBER: 'driver-phone-number',
    DISPATCHER_PHONE_NUMBER: 'driver-dispatcher-phone-number',
    TRACKING_FREQUENCY: 'tracking-frequency',
    TRACKING_FREQUENCY_DURATION: 'tracking-frequency-duration',
}

export const CreateShipmentPage= (props) => {
    const {user} = useBrokerAuth()

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            rememberMe: false
        },
        onSubmit: async (values, actions) => {
            const source = values[FIELD_IDS.PICKUP_LOCATION]
            const destination = values[FIELD_IDS.DROPOFF_LOCATION]
            const phone_number = `+1${values[FIELD_IDS.DRIVER_PHONE_NUMBER].replace(/-/g, "")}`;
            const pickupTz = getTimezone(values[FIELD_IDS.PICKUP_LOCATION])
            const dropoffTz = getTimezone(values[FIELD_IDS.DROPOFF_LOCATION])
            const pickupParsed = mom.tz(values[FIELD_IDS.PICKUP_TIME], moment.defaultFormat, pickupTz).toISOString()
            const dropoffParsed = mom.tz(values[FIELD_IDS.DROPOFF_TIME], moment.defaultFormat, dropoffTz).toISOString()
            const carrier = {
                name: values[FIELD_IDS.DRIVER_NAME],
                phone_number: phone_number,
                dispatcher_phone_number: values[FIELD_IDS.DISPATCHER_PHONE_NUMBER] ? `+1${values[FIELD_IDS.DISPATCHER_PHONE_NUMBER].replace(/-/g, "")}`: null,
            }
            const load = {
                external_reference_id: values[FIELD_IDS.LOAD_ID].trim(),
                pickup: {
                    lat: source.lat,
                    long: source.long,
                    time: pickupParsed,
                    name: source.name,
                },
                dropoff: {
                    lat: destination.lat,
                    long: destination.long,
                    time: dropoffParsed,
                    name: destination.name,
                },
                carrier: carrier,
                company: user,
                status: 0,
            }

            actions.setSubmitting(true)
            await post('/loads', {
                ...load,
                locations: [load.pickup, load.dropoff],
                tracking_config: {
                    minutes_between: fields['Tracking-frequency'] * 60,
                }
            })
            actions.setSubmitting(false)
            actions.resetForm()
            onClose()
        }
    });
    const { shipments, isOpen, onClose, location } = props;
    const [fields, setFields] = useState({
        'load-id': '',
        'Tracking-frequency': 2,
        'Tracking-frequency-period': 'hours',
    })
    const pastLocations = useMemo(() => {
        if (shipments) {
            const mapped = shipments.map((shipment) => [shipment.origin, shipment.destination]).flat()
            return [...new Map(mapped.map(v => [v.name, v])).values()]
        }

        return [];
    }, [shipments])

    console.log(formik.values[FIELD_IDS.DROPOFF_LOCATION])
    return(

                    <Stack spacing={10} color={'black'}>
                        <Stack>
                            <Heading
                                color={'black'}
                                fontSize={'l'}
                                fontFamily={'body'}>
                                Load
                            </Heading>
                            <Divider />
                            <HStack spacing={5} justifyContent={'space-between'}>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.LOAD_ID}>Load ID</FormLabel>
                                    <Input
                                        id={FIELD_IDS.LOAD_ID}
                                        onChange={formik.handleChange}
                                        value={formik.values[FIELD_IDS.LOAD_ID]}
                                    />
                                </FormControl>
                            </HStack>
                        </Stack>


                        {/*pickup*/}
                        <Stack>
                            <Heading
                                color={'black'}
                                fontSize={'l'}
                                fontFamily={'body'}>
                                Pickup
                            </Heading>
                            <Divider />
                            <HStack spacing={5} justifyContent={'space-between'}>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.PICKUP_LOCATION}>Location</FormLabel>
                                    <LocationSearch
                                        pastLocations={pastLocations}
                                        setLocation={(value) => formik.setFieldValue(FIELD_IDS.PICKUP_LOCATION,  value)}/>
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.PICKUP_TIME}>Time</FormLabel>
                                    <InputGroup >
                                        <Input
                                            type={'datetime-local'}
                                            id={FIELD_IDS.PICKUP_TIME}
                                            onChange={formik.handleChange}
                                            value={formik.values[FIELD_IDS.PICKUP_TIME]}
                                        />
                                        <InputRightAddon padding={0}>
                                            <Text padding={5} w={"200px"}>
                                                {getTimezone(formik.values[FIELD_IDS.PICKUP_LOCATION])}
                                            </Text>
                                        </InputRightAddon>
                                    </InputGroup>
                                </FormControl>
                            </HStack>
                        </Stack>

                        {/*dropoff*/}
                        <Stack>
                            <Heading
                                color={'black'}
                                fontSize={'l'}
                                fontFamily={'body'}>
                                Dropoff
                            </Heading>
                            <Divider />
                            <HStack spacing={5} justifyContent={'space-between'}>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DROPOFF_LOCATION}>Location</FormLabel>
                                    <LocationSearch
                                        pastLocations={pastLocations}
                                        setLocation={(value) => formik.setFieldValue(FIELD_IDS.DROPOFF_LOCATION,  value)}/>
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DROPOFF_TIME}>Time</FormLabel>
                                    <InputGroup>
                                        <Input
                                            type={'datetime-local'}
                                            id={FIELD_IDS.DROPOFF_TIME}
                                            onChange={formik.handleChange}
                                            value={formik.values[FIELD_IDS.DROPOFF_TIME]}
                                        />
                                        <InputRightAddon padding="0px" children={
                                            <Text padding={5} w={"200px"}>
                                                {getTimezone(formik.values[FIELD_IDS.DROPOFF_LOCATION])}
                                            </Text>
                                            //     <Select maxW={"170px"} defaultValue={getTimezone(formik.values[FIELD_IDS.DROPOFF_LOCATION])} placeholder='Select timezome' id={FIELD_IDS.DROPOFF_TZ} value={formik.values[FIELD_IDS.DROPOFF_TZ]}>
                                            //         {moment.tz.zonesForCountry("US").map((tz) => {
                                            //             return <option value={tz}>{tz}</option>
                                            //         })}
                                            // </Select>
                                        } />
                                    </InputGroup>
                                </FormControl>
                            </HStack>
                        </Stack>


                        {/*carrier*/}
                        <Stack>
                            <Heading
                                color={'black'}
                                fontSize={'l'}
                                fontFamily={'body'}>
                                Carrier
                            </Heading>
                            <Divider />
                            <HStack spacing={5} justifyContent={'space-between'}>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DRIVER_NAME}>Name</FormLabel>
                                    <Input
                                        id={FIELD_IDS.DRIVER_NAME}
                                        onChange={formik.handleChange}
                                        value={formik.values[FIELD_IDS.DRIVER_NAME]}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DRIVER_PHONE_NUMBER}>Phone number</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon>+1</InputLeftAddon>
                                        <Input
                                            type={'tel'}
                                            id={FIELD_IDS.DRIVER_PHONE_NUMBER}
                                            value={formik.values[FIELD_IDS.DRIVER_PHONE_NUMBER]}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DISPATCHER_PHONE_NUMBER}>Dispatcher phone number</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon>+1</InputLeftAddon>
                                        <Input
                                            type={'tel'}
                                            id={FIELD_IDS.DISPATCHER_PHONE_NUMBER}
                                            value={formik.values[FIELD_IDS.DISPATCHER_PHONE_NUMBER]}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>
                            </HStack>
                        </Stack>


                        {/*tracking-frequency*/}
                        <Stack>
                            <Heading
                                color={'black'}
                                fontSize={'l'}
                                fontFamily={'body'}>
                                Frequency
                            </Heading>
                            <Divider />
                            <HStack spacing={5} justifyContent={'space-between'}>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DRIVER_NAME}>Name</FormLabel>
                                    <InputGroup >
                                        <Input type='number' placeholder={60}
                                               maxW={"60px"}
                                               id={FIELD_IDS.TRACKING_FREQUENCY}
                                               value={formik.values[FIELD_IDS.TRACKING_FREQUENCY]}
                                               onChange={formik.handleChange} />
                                        <InputRightAddon padding="0px" children={  <Select placeholder='Select frequency' id={FIELD_IDS.TRACKING_FREQUENCY_DURATION} value={'hours'} maxWidth='100%'>
                                            <option value='hours'>Hours</option>
                                        </Select>} />

                                    </InputGroup>
                                </FormControl>
                            </HStack>
                        </Stack>
                    </Stack>
    );
}
