import axios from "axios";
import tz from "tz-lookup";
const TOKEN = 'pk.eyJ1IjoidGFudWo5OCIsImEiOiJjbDA2YXVjZzIxZjVjM2JtanZnODdyaHo5In0.RYkjFgmfqGnX02DQ4gXbyA'

export class Places {
    token = 'pk.eyJ1IjoidGFudWo5OCIsImEiOiJjbDA2YXVjZzIxZjVjM2JtanZnODdyaHo5In0.RYkjFgmfqGnX02DQ4gXbyA'

    async reverseGeocodeLocationName(lat, long) {
        const path = `https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?types=place&limit=1&access_token=${this.token}`
        const res = await fetch(path)
        const json = await res.json()
        console.log(json)
        return json.features[0].place_name
    }

}

const locationToServer = (loc) => ({
    lat: loc.geometry.coordinates[1],
    long: loc.geometry.coordinates[0],
    name: loc.place_name,
})

export function forwardGeocodeName(searchString) {
    return axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchString}.json?access_token=${TOKEN}`).then((res) => {
        const mapped = res.data.features.map((feature) => ({
            value: locationToServer(feature),
            label: feature.place_name,
        }))
        return mapped
    })
}

export function getTimezone(location) {
    if(location == null) {
        return 'America/Los_Angeles'
    }

    console.log(tz(location.lat, location.long))
    return tz(location.lat, location.long)
}
