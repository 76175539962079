import {
    chakra,
    Box,
    Button,
    Container,
    Flex, GridItem,
    Icon,
    IconProps,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, SimpleGrid,
    Stack,
    Text, useColorModeValue,
    useDisclosure, VisuallyHidden, Image, Link, Heading, AspectRatio, HStack, useQuery, Collapse, VStack,
} from '@chakra-ui/react';
import React, {useEffect} from "react";
import referlist from "referlist";
import {IoMdCheckmarkCircle, AiFillStar} from "react-icons/all";
import Marquee from "react-fast-marquee";
import WithSpeechBubbles from "./testimonials";
import amplitude from "amplitude-js";
import {useNavigate} from "react-router-dom";
import {usePathQuery} from "../util/query";
import {SignupDetails} from "./signup";
import Hero from "./hero";
import Pricing from "./pricing";
import FeaturesWithImage from "./features";
import {IntercomProvider} from "react-use-intercom";

function HowItWorks() {
        return (
            <div>
            <chakra.h2
        mt={0}
        ml={10}
        fontSize={{ base: "1.5xl", lg: "2xl" }}
        fontWeight={{ base: "bold"}}
        color={useColorModeValue("gray.900", "gray.100")}
        lineHeight="shorter"
        textAlign={{ base: "left-center"}}
    >
    How it works
    </chakra.h2>

    <SimpleGrid
                        columns={{ base: 1, lg: 2, lg: 2 }}
                        spacing={20}
                        px={{ base: 4, lg: 16, xl: 24 }}
                        py={8}
                        mx="auto"
                        bg={useColorModeValue("white", "gray.800")}
                    >
                            <Box>
                              Request carrier location any time with an easy text and visualize it on the map
                              <br />
                              <br />
                               <Image htmlHeight='500px' htmlWidth='500px' src='screenshot-Livetrucks-broker-screen.png' alt='Screenshot of LiveTrucks text' />
                            </Box>
                            <Box>
                              Carrier receives a simple text with link that automatically registers their current location
                              <br />
                              <br />
                               <Image htmlHeight='500px' htmlWidth='500px' src='screenshot-LiveTrucks-Carrier.png' alt='Screenshot of LiveTrucks text' />
                            </Box>

                    </SimpleGrid>
            </div>
        );
}

function CompaniesList() {
    const logos = [
        {
            path: "arrive-logistics.png",
            w: "240px",
        },
        {
            path: "cheeseman.png",
            w: "100px",
        },
        {
            path: "cts.png",
            w: "100px",
        },
        {
            path: "gtz.png",
            w: "100px",
        },
        {
            path: "transplace.png",
            w: "220px",
        },
    ]
        return (
          <Box>
              <Marquee >
                        {logos.map((img) => {
                            return <Image w={img.w} h="50px" marginRight={"50px"} src={img.path} />
                        })}

              </Marquee>
          </Box>
        );
}


function TestimonialList() {
        const Feature = (props) => {
                return (
                    <Box w={"100vw"} >
                            <chakra.h3
                                mb={3}
                                fontSize="lg"
                                lineHeight="shorter"
                                fontWeight="bold"
                                color={useColorModeValue("gray.900")}
                            >
                                    {props.title}
                            </chakra.h3>
                            <chakra.p
                                lineHeight="tall"
                                color={useColorModeValue("gray.600", "gray.400")}
                            >
                                    {props.children}
                            </chakra.p>
                    </Box>
                );
        };

        return (
                    <SimpleGrid
                        columns={{ base: 1, lg: 2, lg: 2 }}
                        spacing={20}
                        px={{ base: 4, lg: 16, xl: 24 }}
                        py={20}
                        mx="auto"
                        bg={useColorModeValue("white", "gray.800")}
                    >
                            <Feature>
                               <Image boxSize='100px' src='person1.jpg' alt='Loretta C.' borderRadius='full' />
                               "I used to spend 2 hours a day doing check calls. Now I spend 5 minutes"
                               <br />
                               <br />
                               - Loretta C. Freight Broker, Atlanta GA
                            </Feature>

                            <Feature>
                              <Image boxSize='100px' src='person2.jpg' alt='Steve M.' borderRadius='full' />
                              "My carriers thank me for using LiveTrucks texts instead of calling them repeatedly for updates"
                              <br />
                              <br />
                               - Steve M. Freight Broker, Phoenix AR
                            </Feature>
                    </SimpleGrid>
        );
}

function FeatureList() {
        const Feature = (props) => {
                return (
                    <Box>
                            <Icon
                                boxSize={12}
                                color={useColorModeValue("brand.700")}
                                mb={4}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                    {props.icon}
                            </Icon>
                            <chakra.h3
                                mb={3}
                                fontSize="lg"
                                lineHeight="shorter"
                                fontWeight="bold"
                                color={useColorModeValue("gray.900")}
                            >
                                    {props.title}
                            </chakra.h3>
                            <chakra.p
                                lineHeight="tall"
                                color={useColorModeValue("gray.600", "gray.400")}
                            >
                                    {props.children}
                            </chakra.p>
                    </Box>
                );
        };

        return (
            <Box py={10} textAlign={{base: 'left', lg: 'center'}} backgroundColor={'blue.50'}>
                <Heading>Feature rich</Heading>
                <Text>
                    Best in class features with exciting updates every week
                </Text>
                    <SimpleGrid
                        columns={{ base: 1, lg: 2, lg: 3 }}
                        spacing={20}
                        px={{ base: 4, lg: 16, xl: 24 }}
                        py={20}
                        mx="auto"
                    >
                            <Feature
                                title="100+ data sources"
                                icon={
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                                        />
                                }
                            >
                                    We integrate with 100+ data sources to get your carrier's location. We use a proprietary algorithm to always get the most up to date location for your carrier.
                            </Feature>

                            <Feature
                                title="Share with your shipper"
                                icon={
                                        <path
                                            fillRule="evenodd"
                                            d="M7 2a1 1 0 00-.707 1.707L7 4.414v3.758a1 1 0 01-.293.707l-4 4C.817 14.769 2.156 18 4.828 18h10.343c2.673 0 4.012-3.231 2.122-5.121l-4-4A1 1 0 0113 8.172V4.414l.707-.707A1 1 0 0013 2H7zm2 6.172V4h2v4.172a3 3 0 00.879 2.12l1.027 1.028a4 4 0 00-2.171.102l-.47.156a4 4 0 01-2.53 0l-.563-.187a1.993 1.993 0 00-.114-.035l1.063-1.063A3 3 0 009 8.172z"
                                            clipRule="evenodd"
                                        />
                                }
                            >
                                    Share a private link with your shipper so they can follow the location of your carrier with you.
                            </Feature>

                            <Feature
                                title="Carrier first"
                                icon={
                                      <Icon as={AiFillStar}/>
                                }
                            >
                                   Our solutions are designed to ensure the most seamless experience for your carrier. We have 98% approval rating by all the carriers we work with!
                            </Feature>
                    </SimpleGrid>
            </Box>
        );
}

function Alerts(){
    return (
        <Flex
            w="full"
            bg="gray.600"
            p={50}
            alignItems="center"
            justifyContent="center"
        >
            <Flex
                maxW="sm"
                w="full"
                mx="auto"
                bg={useColorModeValue("white", "gray.800")}
                shadow="md"
                rounded="lg"
                overflow="hidden"
            >
                <Flex justifyContent="center" alignItems="center" w={12} bg="green.500">
                    <Icon as={IoMdCheckmarkCircle} color="white" boxSize={6} />
                </Flex>

                <Box mx={-3} py={2} px={4}>
                    <Box mx={3}>
                        <chakra.span
                            color={useColorModeValue("green.500", "green.400")}
                            fontWeight="bold"
                        >
                            Success
                        </chakra.span>
                        <chakra.p
                            color={useColorModeValue("gray.600", "gray.200")}
                            fontSize="sm"
                        >
                            Your account was registered!
                        </chakra.p>
                    </Box>
                </Box>
            </Flex>
        </Flex>
    );
};
export function CallToActionWithIllustration(){
        const [email, setEmail] = React.useState('')
        const handleChange = (event) => setEmail(event.target.value)
        const navigate = useNavigate()
        const query = usePathQuery()
        const [showSignup, setShowSignup] = React.useState(false);

        const handleToggle = () => setShowSignup(!showSignup);

        const toSignup = () => navigate('/signup')

    useEffect(() => amplitude.getInstance().logEvent('landing-page-load', {
            'origin': query.get('src'),
        }), [])
        const Feature = (props) => (
            <Flex alignItems="center" color={useColorModeValue(null, "white")}>
                    <Icon
                        boxSize={4}
                        mr={1}
                        color="green.600"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                            <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                            ></path>
                    </Icon>
                    {props.children}
            </Flex>
        );
        return (
            <IntercomProvider appId="apqhtn3o" autoBoot={true}>
            <Stack mx="auto" alignItems={{base: "left", lg: "center"}} spacing={0}>
                        <Hero />
                        {/*    <chakra.h1*/}
                        {/*        mb={3}*/}
                        {/*        fontSize={{ base: "4xl", lg: "5xl" }}*/}
                        {/*        fontWeight={{ base: "bold", lg: "extrabold" }}*/}
                        {/*        color={useColorModeValue("brand.1200", "brand.1200")}*/}
                        {/*        lineHeight="shorter"*/}
                        {/*    >*/}
                        {/*    Complete load visiblity without the headache*/}
                        {/*    </chakra.h1>*/}

                        {/*    <Image htmlHeight="370px" htmlHeight = "730px" src='LiveTrucksDiagram.png' alt='LiveTrucks Diagram' />*/}
                        {/*    <chakra.p*/}
                        {/*        mb={18}*/}
                        {/*        fontSize={{ base: "lg", lg: "xl" }}*/}
                        {/*        color="gray.500"*/}
                        {/*        lineHeight="base"*/}
                        {/*    >*/}
                        {/*    </chakra.p>*/}
                        {/*<Button*/}
                        {/*    mb={"10px"}*/}
                        {/*    as={GridItem}*/}
                        {/*    variant="solid"*/}
                        {/*    colSpan={{ base: "auto", lg: 2 }}*/}
                        {/*    size="lg"*/}
                        {/*    type="submit"*/}
                        {/*    colorScheme="brand"*/}
                        {/*    cursor="pointer"*/}
                        {/*    onClick={() => {*/}
                        {/*        amplitude.getInstance().logEvent('user-click-signup-home')*/}
                        {/*        navigate('/signup')*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    Create Free Account*/}
                        {/*</Button>*/}
                        {/*<Collapse show={showSignup}>*/}
                        {/*            <SignupDetails />*/}
                        {/*                </Collapse>*/}

                        {/*<Stack*/}
                        {/*    display="flex"*/}
                        {/*    direction={{ base: "column", lg: "row" }}*/}
                        {/*    justifyContent={{ base: "start", lg: "center" }}*/}
                        {/*    mb={3}*/}
                        {/*    spacing={{ base: 2, lg: 8 }}*/}
                        {/*    fontSize="xs"*/}
                        {/*    color="gray.600"*/}
                        {/*>*/}
                        {/*    <Feature>No credit card required</Feature>*/}
                        {/*    <Feature>Completely free for the first 20 loads</Feature>*/}
                        {/*    <Feature>Cancel anytime</Feature>*/}
                        {/*    <Feature>Unlimited loads for $10 a month</Feature>*/}
                        {/*</Stack>*/}

                    {/*<CompaniesList />*/}
                    {/*<HowItWorks />*/}

                <Box width={"100%"} backgroundColor={'white'}>
                    <Flex direction={['column', 'row']} justify='space-around' my={'25px'}>
                        <Box>
                            <Image htmlWidth='300px' htmlHeight='220px' src='backed_by.png' alt='Backed by YCombinator' />
                        </Box>
                        <Box>
                            <Image htmlWidth='300px' htmlHeight='220px' src='built_by.png' alt='Built by people from Uber, Amazon, Facebook' />
                        </Box>
                    </Flex>
                </Box>
                {/* <FeaturesWithImage />
                <Pricing onClick={toSignup}/>
                <WithSpeechBubbles /> */}
                <Box width={"100%"} backgroundColor={'white'} px={'50px'} py={'40px'} boxShadow='xs'>
                    <Text>LiveTrucks</Text>
                    <Text>149 New Montgomery St, 4th Floor</Text>
                    <Text>San Francisco, CA 94105</Text>
                </Box>
            </Stack>
            </IntercomProvider>
        );
};
