
import {Form, Formik, Field} from 'formik';
import {
    Box,
    Button, Center, chakra, Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel, GridItem,
    Heading,
    Input, SimpleGrid, Stack,
    useColorModeValue, useToast
} from "@chakra-ui/react";
import {useBrokerAuth} from "../util/auth/broker-auth";
import {useNavigate} from "react-router-dom";
import React from "react";
import amplitude from 'amplitude-js';
import WithSpeechBubbles from "./testimonials";


export function Signup() {
    const {createUser} = useBrokerAuth()
    let navigate = useNavigate();
    function validateName(value) {
        let error
        if (!value) {
            error = 'Required'
        }
        return error
    }

    const validateEmail = (value, props /* only available when using withFormik */) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = 'Invalid email address';
        }

        return error;
    };

    return (
        <Flex
            flexDir={'column'}
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            padding={{
                base: '20px',
                lg: '0px',
            }}
        >
            <Heading mb={3}>
                Sign up today for free!
            </Heading>
            <Box
                mx={'auto'} maxW={'xl'}
                border="2px"
                w={'full'}
                boxShadow={'l'}
                rounded={'md'}
                overflow={'hidden'}>

                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                >
                    <Heading
                        padding="20px"
                        fontSize={'l'}
                        fontFamily={'heading'}>
                        Sign up
                    </Heading>
                    <Divider />
        <Formik
            initialValues={{ name: '', email: '', phoneNumber: '', password: '', password2: ''}}
            onSubmit={(values, actions) => {
                amplitude.getInstance().logEvent("user-signup");
                createUser(values, () => {
                    actions.setSubmitting(false)
                    navigate('/broker')
                })
            }}
        >
            {(props) => (
                <Form id="signup-form">
                    <Stack spacing={5} padding="20px" backgroundColor='gray.50'>
                    <Field name='brokerName' validate={validateName}>
                        {({ field, form }) => (
                            <FormControl isInvalid={form.errors.brokerName && form.touched.brokerName}>
                                <FormLabel htmlFor='brokerName'>Broker name</FormLabel>
                                <Input {...field} id='brokerName' placeholder='name' />
                                <FormErrorMessage>{form.errors.brokerName}</FormErrorMessage>
                            </FormControl>
                        )}
                    </Field>
                    <Field name='email' validate={validateEmail}>
                        {({ field, form }) => (
                            <FormControl isInvalid={form.errors.email && form.touched.email}>
                                <FormLabel htmlFor='email'>Email</FormLabel>
                                <Input {...field} id='email' placeholder='hello@youareawesome.com' />
                                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                            </FormControl>
                        )}
                    </Field>
                    <Field name='phoneNumber' validate={validateName}>
                        {({ field, form }) => (
                            <FormControl isInvalid={form.errors.phoneNumber && form.touched.phoneNumber}>
                                <FormLabel htmlFor='phoneNumber'>Phone number</FormLabel>
                                <Input {...field} id='phoneNumber' placeholder='+11111111111' />
                                <FormErrorMessage>{form.errors.phoneNumber}</FormErrorMessage>
                            </FormControl>
                        )}
                    </Field>
                    <Field name='password'>
                        {({ field, form }) => (
                            <FormControl isInvalid={form.errors.password && form.touched.password}>
                                <FormLabel htmlFor='userName'>Password</FormLabel>
                                <Input type={'password'}{...field} id='password' />
                                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                            </FormControl>
                        )}
                    </Field>
                    <Button
                        mt={4}
                        colorScheme='teal'
                        isLoading={props.isSubmitting}
                        type='submit'
                    >
                        Submit
                    </Button>
                    </Stack>
                </Form>

            )}
        </Formik>
                </Box>
            </Box>
        </Flex>
    )
}

//
// export function SignupDetails() {
//     const {createUser} = useAuth()
//     const toast = useToast()
//     let navigate = useNavigate();
//     function validateName(value) {
//         let error
//         if (!value) {
//             error = 'Required'
//         }
//         return error
//     }
//
//     const validateEmail = (value, props /* only available when using withFormik */) => {
//         let error;
//         if (!value) {
//             error = 'Required';
//         } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
//             error = 'Invalid email address';
//         }
//
//         return error;
//     };
//
//     return (
//         <Stack
//             flexDir={{base: "column", lg: "row"}}
//             minH={'100vh'}
//             align={'center'}
//             justify={'center'}
//             padding={{
//                 base: '20px',
//                 lg: '0px',
//             }}
//         >
//             <Heading mb={3}>
//                 Sign up today for free!
//             </Heading>
//             <Box
//                 mx={'auto'} maxW={'xl'}
//                 border="2px"
//                 w={'full'}
//                 boxShadow={'l'}
//                 rounded={'md'}
//                 overflow={'hidden'}>
//
//                 <Box
//                     rounded={'lg'}
//                     bg={useColorModeValue('white', 'gray.700')}
//                 >
//                     <Heading
//                         padding="20px"
//                         fontSize={'l'}
//                         fontFamily={'heading'}>
//                         Sign up
//                     </Heading>
//                     <Divider />
//                     <Formik
//                         initialValues={{ name: '', email: '', phoneNumber: ''}}
//                         onSubmit={(values, actions) => {
//                             amplitude.getInstance().logEvent("user-signup", values);
//                             toast({
//                                 title: 'Signup successful',
//                                 description: "We will reach out to you within 24 hours",
//                                 status: 'success',
//                                 duration: 9000,
//                                 isClosable: true,
//                             })
//                             actions.setSubmitting(false)
//                         }}
//                     >
//                         {(props) => (
//                             <Form id="signup-form">
//                                 <Stack spacing={5} padding="20px" backgroundColor='gray.50'>
//                                     <Field name='brokerName' validate={validateName}>
//                                         {({ field, form }) => (
//                                             <FormControl isInvalid={form.errors.brokerName && form.touched.brokerName}>
//                                                 <FormLabel htmlFor='brokerName'>Broker name</FormLabel>
//                                                 <Input {...field} id='brokerName' placeholder='name' />
//                                                 <FormErrorMessage>{form.errors.brokerName}</FormErrorMessage>
//                                             </FormControl>
//                                         )}
//                                     </Field>
//                                     <Field name='email' validate={validateEmail}>
//                                         {({ field, form }) => (
//                                             <FormControl isInvalid={form.errors.email && form.touched.email}>
//                                                 <FormLabel htmlFor='email'>Email</FormLabel>
//                                                 <Input {...field} id='email' placeholder='hello@youareawesome.com' />
//                                                 <FormErrorMessage>{form.errors.email}</FormErrorMessage>
//                                             </FormControl>
//                                         )}
//                                     </Field>
//                                     <Field name='phoneNumber' validate={validateName}>
//                                         {({ field, form }) => (
//                                             <FormControl isInvalid={form.errors.phoneNumber && form.touched.phoneNumber}>
//                                                 <FormLabel htmlFor='phoneNumber'>Phone number</FormLabel>
//                                                 <Input {...field} id='phoneNumber' placeholder='+11111111111' />
//                                                 <FormErrorMessage>{form.errors.phoneNumber}</FormErrorMessage>
//                                             </FormControl>
//                                         )}
//                                     </Field>
//                                     <Button
//                                         mt={4}
//                                         colorScheme='teal'
//                                         isLoading={props.isSubmitting}
//                                         type='submit'
//                                     >
//                                         Submit
//                                     </Button>
//                                 </Stack>
//                             </Form>
//
//                         )}
//                     </Formik>
//                 </Box>
//             </Box>
//         </Stack>
//     )
// }

export function SignupDetails(){
    const toast = useToast()

    return (
        <Box>
        <Box px={8} py={24} mx="auto" backgroundColor={'blue.200'}>
            <SimpleGrid
                alignItems="center"
                w={{ base: "full", xl: 11 / 12 }}
                columns={{ base: 1, lg: 11 }}
                gap={{ base: 0, lg: 24 }}
                mx="auto"
            >
                <GridItem
                    colSpan={{ base: "auto", lg: 7 }}
                    textAlign={{ base: "center", lg: "left" }}
                >
                    <chakra.h1
                        mb={4}
                        fontSize={{ base: "3xl", lg: "4xl" }}
                        fontWeight="bold"
                        lineHeight={{ base: "shorter", lg: "none" }}
                        color={useColorModeValue("gray.900", "gray.200")}
                        letterSpacing={{ base: "normal", lg: "tight" }}
                    >
                        Ready to start saving hours on tracking?
                    </chakra.h1>
                    <chakra.p
                        mb={{ base: 10, lg: 4 }}
                        fontSize={{ base: "lg", lg: "xl" }}
                        fontWeight="thin"
                        color="gray.500"
                        letterSpacing="wider"
                    >
                       Completely managed tracking with just few button clicks
                    </chakra.p>
                </GridItem>
                <GridItem colSpan={{ base: "auto", lg: 4 }}>
                    <Box mb={6} rounded="lg" shadow="xl" backgroundColor={'white'}>
                        <SimpleGrid
                            columns={1}
                            px={6}
                            py={4}
                            spacing={4}
                            borderBottom="solid 1px"
                            borderColor={useColorModeValue("gray.200", "gray.700")}
                        >
                            <Formik
                                initialValues={{ name: '', email: '', phoneNumber: ''}}
                                onSubmit={(values, actions) => {
                                    amplitude.getInstance().logEvent("user-signup", values);
                                    toast({
                                        title: 'Signup successful',
                                        description: "We will reach out to you within 24 hours",
                                        status: 'success',
                                        duration: 9000,
                                        isClosable: true,
                                    })
                                    actions.setSubmitting(false)
                                }}
                            >
                                {(props) => (
                                    <Form id="signup-form">
                                        <Stack spacing={5} padding="20px">
                                            <Field name='brokerName'>
                                                {({ field, form }) => (
                                                    <FormControl isInvalid={form.errors.brokerName && form.touched.brokerName}>
                                                        <FormLabel htmlFor='brokerName'>Broker name</FormLabel>
                                                        <Input {...field} id='brokerName' placeholder='name' />
                                                        <FormErrorMessage>{form.errors.brokerName}</FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name='email'>
                                                {({ field, form }) => (
                                                    <FormControl isInvalid={form.errors.email && form.touched.email}>
                                                        <FormLabel htmlFor='email'>Email</FormLabel>
                                                        <Input {...field} id='email' placeholder='hello@youareawesome.com' />
                                                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name='phoneNumber'>
                                                {({ field, form }) => (
                                                    <FormControl isInvalid={form.errors.phoneNumber && form.touched.phoneNumber}>
                                                        <FormLabel htmlFor='phoneNumber'>Phone number</FormLabel>
                                                        <Input {...field} id='phoneNumber' placeholder='+11111111111' />
                                                        <FormErrorMessage>{form.errors.phoneNumber}</FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Button
                                                mt={4}
                                                colorScheme='teal'
                                                isLoading={props.isSubmitting}
                                                type='submit'
                                            >
                                                Submit
                                            </Button>
                                        </Stack>
                                    </Form>

                                )}
                            </Formik>
                        </SimpleGrid>
                    </Box>
                </GridItem>
            </SimpleGrid>
        </Box>
            <WithSpeechBubbles />
        </Box>
    );
};
