import React, {useMemo} from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api';
// import {
//     StaticGoogleMap,
//     Marker,
//     Path,
// } from 'react-static-google-map';
import DeckGL from "deck.gl";
import Map, {Marker} from "react-map-gl";
import {GiTruck} from "react-icons/all";
import Pin from "./pin";
import CITIES from './../cities.json';
import Mapp from "./map";
import GoogleMaps from "./map/google-maps";
const containerStyle = {
    width: '100%',
    height: '400px'
};

const center = {
    lat: 39.742043,
    lng: -104.299591
};


const initialViewState = {
    longitude: -122.43,
    latitude: 37.775,
    zoom: 5
};

export function ShipmentMap(props) {

    const pins = useMemo(
        () =>
            CITIES.map((city, index) => (
                <Marker
                    key={`marker-${index}`}
                    longitude={city.longitude}
                    latitude={city.latitude}
                    anchor="bottom"
                >
                    <Pin />
                </Marker>
            )),
        []
    );

    return (
        <GoogleMaps {...props} />
        );
}



export default React.memo(ShipmentMap)
