interface TrackingFrequency {
    every: number;
    period: 'minutes' | 'hours'
}

export const trackingFrequencyToSeconds = (frequency: TrackingFrequency) => {
    let multiplier;
    switch (frequency.period) {
        case "hours":
            multiplier = 3600
            break;
        case "minutes":
            multiplier = 60
            break;
    }

    return frequency.every * multiplier * 1000
}