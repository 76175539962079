import * as React from 'react';
import {useState, useMemo, useEffect} from 'react';
import {render} from 'react-dom';
import Map, {
    Marker,
    Popup,
    NavigationControl,
    FullscreenControl,
    ScaleControl,
    GeolocateControl, Source, Layer
} from 'react-map-gl';

// added the following 6 lines.
import mapboxgl from 'mapbox-gl';


import ControlPanel from './control-panel';
import Pin from './pin';

import {GiTruck, HiLocationMarker} from "react-icons/all";
import {MdTripOrigin} from "react-icons/md"
import {Box, Center, Container, Icon, Stack, Text, useColorModeValue} from "@chakra-ui/react";
import {customFloor} from "../../util/round";

const TOKEN = ''; // Set your mapbox token here

const locMapper = (loc) => `${loc.long},${loc.lat}`
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export default function Mapp(props) {
    const [completedCords, setcompletedCords] = React.useState(null);
    const [remainingCords, setRemainingCords] = React.useState(null);

    const {origin, locations, destination} = props;
    const reducedLocations = useMemo(() => {
        const reversed = [].concat(locations).reverse();
        const floorRatio = locations / 23;
        const groups = reversed.reduce((groups, location) => {
            const date = new Date(location.recordedAt);
            date.setHours(customFloor(date.getHours(), floorRatio), 0, 0);
            groups[date] = [location]

            return groups;
        }, {})

        return Object.values(groups).map((date) => date[0])
    }, [locations]);

  const parsedLocations = useMemo(() => {
        return `${locMapper(origin)};${reducedLocations.map((loc) => {
            return locMapper(loc.location)
        }).join(';')}`
  }, [reducedLocations, origin])
    useEffect(() => {
        if (reducedLocations.length !== 0) {
            fetch('' +
                'https://api.mapbox.com/directions/v5/mapbox/driving/' +
                `${parsedLocations}?` +
                'geometries=geojson&' +
                'access_token=pk.eyJ1IjoidGFudWo5OCIsImEiOiJjbDA2YXY4ZTQyNjEyM2pzMHRnYnZ4MXk5In0.h8b-3V8ej64d2xNGQCdfQg').then(
                (res) => res.json()).then((results) => {
                setcompletedCords(results.routes[0].geometry)
            })
            const lastLocation = reducedLocations[reducedLocations.length - 1]
            const remainingRoute = `${locMapper(lastLocation.location)};${locMapper(destination)}`
            fetch('' +
                'https://api.mapbox.com/directions/v5/mapbox/driving/' +
                `${remainingRoute}?` +
                'geometries=geojson&' +
                'access_token=pk.eyJ1IjoidGFudWo5OCIsImEiOiJjbDA2YXY4ZTQyNjEyM2pzMHRnYnZ4MXk5In0.h8b-3V8ej64d2xNGQCdfQg').then(
                (res) => res.json()).then((results) => {
                setRemainingCords(results.routes[0].geometry)
            })
        } else {
            const remainingRoute = `${locMapper(origin)};${locMapper(destination)}`
            fetch('' +
                'https://api.mapbox.com/directions/v5/mapbox/driving/' +
                `${remainingRoute}?` +
                'geometries=geojson&' +
                'access_token=pk.eyJ1IjoidGFudWo5OCIsImEiOiJjbDA2YXY4ZTQyNjEyM2pzMHRnYnZ4MXk5In0.h8b-3V8ej64d2xNGQCdfQg').then(
                (res) => res.json()).then((results) => {
                setRemainingCords(results.routes[0].geometry)
            })
        }
  }, [parsedLocations, origin, destination])


    const completed = React.useMemo(() => {
        return completedCords
    }, [completedCords]);


  return (
    <>
                <Map
                    initialViewState={{
                        latitude: 40,
                        longitude: -100,
                        zoom: 3.5,
                        bearing: 0,
                        pitch: 0
                    }}
                    style={{
                        height: '50vh',
                        overflow: 'none'
                    }}
                    mapStyle="mapbox://styles/mapbox/dark-v9"
                    mapboxAccessToken="pk.eyJ1IjoidGFudWo5OCIsImEiOiJjbDA2YXY4ZTQyNjEyM2pzMHRnYnZ4MXk5In0.h8b-3V8ej64d2xNGQCdfQg"
                >
                    {completed && (
                    <Source id="polylineLayer" type="geojson" data={completed}>
                        <Layer
                            id="lineLayer"
                            type="line"
                            source="my-data"
                            layout={{
                                "line-join": "round",
                                "line-cap": "round"
                            }}
                            paint={{
                                "line-color": "blue",
                                "line-width": 3
                            }}
                        />
                    </Source>)}
                    <Source id="polylineLayer2" type="geojson" data={remainingCords}>
                        <Layer
                            id="lineLayer2"
                            type="line"
                            source="my-data"
                            layout={{
                                "line-join": "round",
                                "line-cap": "round"
                            }}
                            paint={{
                                "line-color": "gray",
                                "line-width": 3
                            }}
                        />
                    </Source>
                    <Marker longitude={destination.long} latitude={destination.lat} color="red" scale={0.6}/>
                </Map>
    </>
  );
}
