import * as React from "react";
import {useLocation, Navigate} from "react-router-dom";
import {useEffect} from "react";
import axios from "axios";
import {BASE_URL, fetcher} from "../requests/carrier-axios";

let AuthContext = React.createContext(null);

const formFieldsToServer = (formFields) => ({
    first_name: '-',
    last_name: '-',
    company: {
        phone_number: formFields.phoneNumber,
        name: formFields.brokerName,
        type: 1,
    },
    email: formFields.email,
    password: formFields.password,
})

export function CarrierAuthProvider({ children }) {
    const [token, setToken] = React.useState(null)
    const [user, setUser] = React.useState(null);
    const [isFetching, setIsFetching] = React.useState(true)
    const getUser = React.useCallback(() => {
        return fetcher('/carrier/user').then((res) => {
            setUser(res)
        }).finally(() => setIsFetching(false))
    }, [setUser, setIsFetching])
    useEffect(() => {
        getUser()
    }, [getUser])

    let signin = (phoneNumber, code) => {
        return axios
            .post(`${BASE_URL}/unauth/carrier_login`, { phone_number: phoneNumber, code })
            .then((res) => {
                if(res.status === 200) {
                    console.log(res.data)
                    localStorage.setItem('carrier-token', JSON.stringify(res.data))
                    return getUser()
                }
            })
            .catch((err) => {
                console.log(err.response.data.detail.toString());
            });
    };

    let triggerSignInText = async (phoneNumber, callback) => {
        fetcher(`/unauth/verification_text/${phoneNumber}`, {
            skipAuth: true,
        }).then(() => {if (callback) {callback()}});
    }

    let signout = (callback) => {
        fetch('https://livetrucks-be.herokuapp.com/api/logout', {
            method: 'POST',
            credentials: 'include',
        })
        setUser(null)
        localStorage.removeItem('token')
        callback()
    };

    let value = { user, isFetching, signin, signout, triggerSignInText };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useCarrierAuth() {
    return React.useContext(AuthContext);
}

export function RequireCarrierAuth({ children }) {
    let auth = useCarrierAuth();
    let location = useLocation();
    if (auth.isFetching){
        return null;
    }

    if (!auth.user) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/carrier/login" state={{ from: location }} replace />;
    }

    return children;
}
