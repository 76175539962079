import {useBrokerAuth} from "../util/auth/broker-auth";
import {useFormik} from "formik";
import {post} from "../util/requests/axios";
import {useMemo, useState} from "react";
import {
    Button,
    Divider, FormControl, FormLabel, Heading, HStack, Input, InputGroup, InputLeftAddon, InputRightAddon,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Select, Stack,
    useColorModeValue
} from "@chakra-ui/react";
import {FIELD_IDS, LocationSearch} from "./create-shipment-modal";

export const EditShipmentModal = (props) => {
    const {user} = useBrokerAuth()
    const { isOpen, onClose, location, shipment } = props;
    console.log(new Date(shipment.destination.time).toISOString())
    const formik = useFormik({
        initialValues: {
            [FIELD_IDS.LOAD_ID]: shipment.loadReference,
            [FIELD_IDS.DROPOFF_LOCATION]: shipment.destination,
            [FIELD_IDS.DROPOFF_TIME]: shipment.destination.time,
            [FIELD_IDS.PICKUP_LOCATION]: shipment.origin,
            [FIELD_IDS.PICKUP_TIME]: shipment.origin.time,
            [FIELD_IDS.DRIVER_PHONE_NUMBER]: shipment.carrier.phone_number,
            [FIELD_IDS.DRIVER_NAME]: shipment.carrier.name,
            [FIELD_IDS.TRACKING_FREQUENCY]: shipment.trackingJob.frequency,
        },
        onSubmit: async (values, actions) => {
            const source = values[FIELD_IDS.PICKUP_LOCATION]
            const destination = values[FIELD_IDS.DROPOFF_LOCATION]
            const phone_number = `+1${values[FIELD_IDS.DRIVER_PHONE_NUMBER].replace(/-/g, "")}`;
            const pickupParsed = new Date(values[FIELD_IDS.PICKUP_TIME]).toISOString()
            const dropoffParsed = new Date(values[FIELD_IDS.DROPOFF_TIME]).toISOString()
            const carrier = {
                name: values[FIELD_IDS.DRIVER_NAME],
                phone_number: phone_number,
            }
            const load = {
                external_reference_id: values[FIELD_IDS.LOAD_ID],
                pickup: {
                    lat: source.lat,
                    long: source.long,
                    time: pickupParsed,
                    name: source.name,
                },
                dropoff: {
                    lat: destination.lat,
                    long: destination.long,
                    time: dropoffParsed,
                    name: destination.name,
                },
                carrier: carrier,
                company: user,
                status: 0,
            }

            actions.setSubmitting(true)
            await post('/loads', {
                ...load,
                locations: [load.pickup, load.dropoff],
                tracking_config: {
                    minutes_between: fields['Tracking-frequency'] * 60,
                }
            })
            actions.setSubmitting(false)
            actions.resetForm()
            onClose()
        }
    });

    const [fields, setFields] = useState({
        'load-id': '',
        'Tracking-frequency': 2,
        'Tracking-frequency-period': 'hours',
    })
    const pastLocations = [];

    return(
        <Modal isOpen={isOpen} onClose={onClose} maxWidth={'70vw'} size={'3xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader color={useColorModeValue('black', 'black')}>Track new shipment</ModalHeader>
                <ModalCloseButton color={useColorModeValue('white', 'black')}/>
                <Divider />
                <ModalBody color={useColorModeValue('gray.50', 'gray.700')} marginTop="10px">
                    <Stack spacing={10} color={'black'}>
                        <Stack>
                            <Heading
                                color={'black'}
                                fontSize={'l'}
                                fontFamily={'body'}>
                                Load
                            </Heading>
                            <Divider />
                            <HStack spacing={5} justifyContent={'space-between'}>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.LOAD_ID}>Load ID</FormLabel>
                                    <Input
                                        id={FIELD_IDS.LOAD_ID}
                                        onChange={formik.handleChange}
                                        value={formik.values[FIELD_IDS.LOAD_ID]}
                                    />
                                </FormControl>
                            </HStack>
                        </Stack>


                        {/*pickup*/}
                        <Stack>
                            <Heading
                                color={'black'}
                                fontSize={'l'}
                                fontFamily={'body'}>
                                Pickup
                            </Heading>
                            <Divider />
                            <HStack spacing={5} justifyContent={'space-between'}>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.PICKUP_LOCATION}>Location</FormLabel>
                                    <LocationSearch
                                        defaultValue={{
                                            value: shipment.origin,
                                            label: shipment.origin.name,
                                        }}
                                        pastLocations={pastLocations}
                                        setLocation={(value) => formik.setFieldValue(FIELD_IDS.PICKUP_LOCATION,  value)}/>
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.PICKUP_TIME}>Time</FormLabel>
                                    <Input
                                        type={'datetime-local'}
                                        id={FIELD_IDS.PICKUP_TIME}
                                        onChange={formik.handleChange}
                                        value={formik.values[FIELD_IDS.PICKUP_TIME]}
                                    />
                                </FormControl>
                            </HStack>
                        </Stack>

                        {/*dropoff*/}
                        <Stack>
                            <Heading
                                color={'black'}
                                fontSize={'l'}
                                fontFamily={'body'}>
                                Dropoff
                            </Heading>
                            <Divider />
                            <HStack spacing={5} justifyContent={'space-between'}>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DROPOFF_LOCATION}>Location</FormLabel>
                                    <LocationSearch
                                        defaultValue={{
                                            value: shipment.destination,
                                            label: shipment.destination.name,
                                        }}
                                        pastLocations={pastLocations}
                                        setLocation={(value) => formik.setFieldValue(FIELD_IDS.DROPOFF_LOCATION,  value)}/>
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DROPOFF_TIME}>Time</FormLabel>
                                    <Input
                                        type={'datetime-local'}
                                        id={FIELD_IDS.DROPOFF_TIME}
                                        onChange={formik.handleChange}
                                        value={formik.values[FIELD_IDS.DROPOFF_TIME]}
                                    />
                                </FormControl>
                            </HStack>
                        </Stack>


                        {/*carrier*/}
                        <Stack>
                            <Heading
                                color={'black'}
                                fontSize={'l'}
                                fontFamily={'body'}>
                                Carrier
                            </Heading>
                            <Divider />
                            <HStack spacing={5} justifyContent={'space-between'}>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DRIVER_NAME}>Name</FormLabel>
                                    <Input
                                        id={FIELD_IDS.DRIVER_NAME}
                                        onChange={formik.handleChange}
                                        value={formik.values[FIELD_IDS.DRIVER_NAME]}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DROPOFF_TIME}>Phone number</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon>+1</InputLeftAddon>
                                        <Input
                                            type={'tel'}
                                            id={FIELD_IDS.DRIVER_PHONE_NUMBER}
                                            value={formik.values[FIELD_IDS.DRIVER_PHONE_NUMBER]}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>
                            </HStack>
                        </Stack>


                        {/*tracking-frequency*/}
                        <Stack>
                            <Heading
                                color={'black'}
                                fontSize={'l'}
                                fontFamily={'body'}>
                                Frequency
                            </Heading>
                            <Divider />
                            <HStack spacing={5} justifyContent={'space-between'}>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DRIVER_NAME}>Name</FormLabel>
                                    <InputGroup >
                                        <Input type='number' placeholder={60}
                                               maxW={"60px"}
                                               id={FIELD_IDS.TRACKING_FREQUENCY}
                                               value={formik.values[FIELD_IDS.TRACKING_FREQUENCY]}
                                               onChange={formik.handleChange} />
                                        <InputRightAddon padding="0px" children={  <Select placeholder='Select frequency' id={FIELD_IDS.TRACKING_FREQUENCY_DURATION} value={'hours'} maxWidth='100%'>
                                            <option value='hours'>Hours</option>
                                        </Select>} />

                                    </InputGroup>
                                </FormControl>
                            </HStack>
                        </Stack>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button color={'white'} bg={'black'} onClick={formik.submitForm}>
                        Submit
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
