import React, {useCallback, useState} from "react";
import {
    Box,
    Button, Checkbox,
    Divider, Flex,
    FormControl, FormErrorMessage, FormLabel, Heading, HStack, Input, Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Stack, Text, useColorModeValue
} from "@chakra-ui/react";
import {useLocation, useNavigate} from "react-router-dom";
import {useBrokerAuth} from "../util/auth/broker-auth"
import {Field, Form, Formik} from "formik";
import amplitude from "amplitude-js";

export function LoginPage() {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const onSignin = useCallback(() => {
        fetch('https://livetrucks-be.herokuapp.com/api/login', {
            method: 'POST',
            credentials: 'include',
            body: new URLSearchParams({
                username: userName,
                password,
            })
        }).then((response) => {
            if(response.status == 200) {
            }
        })
    }, [userName, password])

    const onChangeUserName = (event) => {
        setUserName(event.target.value)
    }
    const onChangePassword = (event) => {
        setPassword(event.target.value)
    }

    let navigate = useNavigate();
    let location = useLocation();
    let auth = useBrokerAuth();

    let from = location.state?.from?.pathname || "/broker";

    function handleSubmit(event) {
        auth.signin(userName, password, () => {
            // Send them back to the page they tried to visit when they were
            // redirected to the login page. Use { replace: true } so we don't create
            // another entry in the history stack for the login page.  This means that
            // when they get to the protected page and click the back button, they
            // won't end up back on the login page, which is also really nice for the
            // user experience.
            navigate(from, { replace: true });
        });
    }

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Heading fontSize={'3xl'}>Sign in</Heading>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={4}>
                        <Formik
                            initialValues={{ email: '', password: ''}}
                            onSubmit={(values, actions) => {
                                auth.signin(values.email.toLowerCase().trim(), values.password.trim(), () => {
                                    // Send them back to the page they tried to visit when they were
                                    // redirected to the login page. Use { replace: true } so we don't create
                                    // another entry in the history stack for the login page.  This means that
                                    // when they get to the protected page and click the back button, they
                                    // won't end up back on the login page, which is also really nice for the
                                    // user experience.
                                    navigate(from, { replace: true });
                                    actions.setSubmitting(false)
                                });
                            }}
                        >
                            {(props) => (
                                <Form id="signup-form">
                                    <Stack spacing={5} padding="20px">
                                        <Field name='email'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.email && form.touched.email}>
                                                    <FormLabel htmlFor='email'>Email</FormLabel>
                                                    <Input {...field} id='email' placeholder='hello@hello.com' />
                                                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='password'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.password && form.touched.password}>
                                                    <FormLabel htmlFor='email'>Password</FormLabel>
                                                    <Input {...field} id='password' placeholder='' type="password" />
                                                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Button
                                            mt={4}
                                            colorScheme='teal'
                                            isLoading={props.isSubmitting}
                                            type='submit'
                                        >
                                            Submit
                                        </Button>
                                    </Stack>
                                </Form>

                            )}
                        </Formik>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    );
}
