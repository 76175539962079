import React, {useCallback, useEffect, useState} from "react";
import {
    Box,
    Button, Checkbox,
    Divider, Flex,
    FormControl, FormErrorMessage, FormLabel, Heading, HStack, Input, InputGroup, InputLeftAddon, Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, PinInput, PinInputField, Stack, Text, useColorModeValue
} from "@chakra-ui/react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import amplitude from "amplitude-js";
import {useCarrierAuth} from "../util/auth/carrier-auth";
import {Step, Steps, useSteps} from "chakra-ui-steps";



export function CarrierLogin() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('');

    const onChangePhoneNumber = (event) => {
        setPhoneNumber(event.target.value)
    }
    const onChangeCode = (event) => {
        setCode(event.target.value)
    }

    let navigate = useNavigate();
    let location = useLocation();
    let auth = useCarrierAuth();

    let from = location.state?.from?.pathname || "/carrier";

    const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
        initialStep: 0,
    });

    console.log(activeStep)
    useEffect(() => {
        if (activeStep == 1) {
            auth.triggerSignInText(`+1${phoneNumber}`)
        }
    }, [activeStep])
    const steps = [
        { label: 'Enter your phone number', content: (
            <Stack padding={10}>
                <InputGroup>
                    <InputLeftAddon children='+1' />
                    <Input type='tel' placeholder='phone number' value={phoneNumber} onChange={(event) => setPhoneNumber(event.target.value)}/>
                </InputGroup>
            </Stack>
            )},
        { label: 'Verify', content: (
                <HStack padding={10}>
                    <PinInput otp onComplete={(value) => auth.signin(`+1${phoneNumber}`, value).then(()  => navigate(from, { replace: true }))}>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                    </PinInput>
                </HStack>
            )},
    ];


    return (
        <Flex
            minH={'100vh'}
            paddingTop={10}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} w={'md'} py={10} px={6}>
                <Stack>
                    <Heading fontSize={'2xl'}>Sign in</Heading>
                </Stack>
                <Box  rounded={'lg'}
                      bg={useColorModeValue('white', 'gray.700')}
                      boxShadow={'lg'}
                      p={8}>
                    <Steps activeStep={activeStep}>
                        {steps.map(({ label, content }) => (
                            <Step label={label} key={label}>
                                {content}
                            </Step>
                        ))}
                    </Steps>
                    {activeStep === steps.length ? (
                        <Flex p={4}>
                            <Button mx="auto" size="sm" onClick={reset}>
                                Reset
                            </Button>
                        </Flex>
                    ) : (
                        <Flex width="100%" justify="flex-end">
                            <Button
                                isDisabled={activeStep === 0}
                                mr={4}
                                onClick={prevStep}
                                size="sm"
                                variant="ghost"
                            >
                                Prev
                            </Button>
                            <Button size="sm" onClick={nextStep}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Flex>
                    )}
                </Box>
            </Stack>
        </Flex>
    );
}
