
import {Form, Formik, Field} from 'formik';
import {
    Box,
    Button, Center, chakra, Container, Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel, GridItem,
    Heading, HStack,
    Input, SimpleGrid, Stack, Text,
    useColorModeValue, useToast, VStack
} from "@chakra-ui/react";
import {useBrokerAuth} from "../util/auth/broker-auth";
import {useNavigate} from "react-router-dom";
import React from "react";
import amplitude from 'amplitude-js';
import WithSpeechBubbles from "./testimonials";


export function Support(){
    return (
        <Box>
        <Box px={8} py={24} mx="auto" backgroundColor={'white'}>
                <Container maxW={"7xl"}>
                    <chakra.h1
                        mb={4}
                        fontSize={{ base: "3xl", lg: "4xl" }}
                        fontWeight="bold"
                        lineHeight={{ base: "shorter", lg: "none" }}
                        color={useColorModeValue("gray.900", "gray.200")}
                        letterSpacing={{ base: "normal", lg: "tight" }}
                    >
                        Need support?
                    </chakra.h1>
                    <chakra.p
                        mb={{ base: 10, lg: 4 }}
                        fontSize={{ base: "lg", lg: "xl" }}
                        color="black"
                        letterSpacing="wider"
                    >
                       We work really hard to provide <b> 24 x 7 </b> customer support to all our customers.
                       Reach out to us with any questions or feedback.
                    </chakra.p>
                    <VStack align={"flex-start"}>
                        <HStack>
                            <Text fontSize={{ base: "lg", lg: "xl" }} >
                            Email:
                        </Text>
                            <Heading size={"md"}>
                                support@livetrucks.io
                            </Heading>
                        </HStack>

                        <HStack>
                            <Text fontSize={{ base: "lg", lg: "xl" }} >
                                Helpline:
                            </Text>
                            <Heading size={"md"}>
                                +1(424) 237-3962
                            </Heading>
                        </HStack>

                    </VStack>
            </Container>
        </Box>
        </Box>
    );
};
