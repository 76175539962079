import keymirror from "keymirror";

export const LoadStatus = Object.freeze({
    0: 'UNKNOWN',
    1: 'CREATED',
    2: 'IN_PROGRESS',
    3: 'FINISHED',
    4: 'CONTACTING_CARRIER',
})

export class TrackingMode {
    static UNKNOWN = new TrackingMode(0)
    static TEXT = new TrackingMode(1)
    static APP = new TrackingMode(2)
    static OUTBOUND_CALL = new TrackingMode(3)
    static INBOUND_CALL = new TrackingMode(4)
    constructor(value) {
        this.value = value;
    }

    toServer() {
        return this.value
    }
}


export const EVENT_NAMES = keymirror({
    CARRIER_TRACKING_PAGE_LOAD: null,
    CARRIER_CHOOSE_METHOD_STEP_ONE: null,
    CARRIER_CHOOSE_METHOD_FINISH: null,
})
