import {titleCase} from "./util/strings";
import {LoadStatus, TrackingMode} from "./constants";

export class Load {
    id = '';
    status = 0;
    loadReference = '';
    origin = undefined;
    destination = undefined;
    displayStatus = '';
    lastLocation = undefined;
    sharingLink = undefined;
    trackingJob = undefined;
    trackingRecords = undefined;
    carrier = undefined;

    constructor(serverObj) {
        this.id = serverObj.ID;
        this.status = serverObj.load_status;
        this.loadReference = serverObj.external_reference_id;
        // this.origin = serverObj.Pickup;
        // this.destination = serverObj.Dropoff;
        this.origin = serverObj.locations[0] || {}
        this.destination = serverObj.locations[1] || {}
        this.displayStatus = titleCase(LoadStatus[this.status]);
        this.sharingLink = serverObj.sharing_link
        this.trackingJob = serverObj.tracking_config
        this.trackingRecords = serverObj.tracking_records ? serverObj.tracking_records.map((record) => new TrackingRecord(record)) : []
        this.trackingRecords = this.trackingRecords.sort((a,b) => a.recordedAt - b.recordedAt)
        this.lastLocation = this.trackingRecords.length > 0 ? this.trackingRecords[this.trackingRecords.length - 1]: undefined;
        this.carrier = serverObj.carrier;
        this.exceptions = serverObj.exceptions;
    }

    getStatus() {
        return this.displayStatus;
    }
}

export class Location {
    id = '';
    name = ''
    lat = 0.0;
    long = 0.0;

    constructor(serverObj) {
        this.id = serverObj.id;
        this.name = serverObj.name;
        this.lat = serverObj.lat;
        this.long = serverObj.long;
    }
}

export class TrackingRecord {
    id = '';
    recordedAt = new Date();
    location = undefined;
    note = undefined;

    constructor(serverObj) {
        this.id = serverObj.id;
        this.recordedAt = new Date(serverObj.created_at);
        this.location = new Location(serverObj.location);
        this.mode = new TrackingMode(serverObj.mode)
        this.note = serverObj.note
    }
}
