import {useBrokerAuth} from "../util/auth/broker-auth";
import {useFormik} from "formik";
import {getTimezone} from "../util/places";
import mom from "moment";
import moment from "moment-timezone";
import {post} from "../util/requests/admin";
import {useMemo, useState} from "react";
import {
    Button, Container,
    Divider, FormControl, FormLabel, Heading, HStack, Input, InputGroup, InputLeftAddon, InputRightAddon,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Select, Stack, Text,
    useColorModeValue, useToast, VStack
} from "@chakra-ui/react";
import {LocationSearch} from "../broker/create-shipment-modal";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const FIELD_IDS = {
    LOAD_ID: 'load-id',
    SENDER_EMAIL: 'sender-email',
    PICKUP_TIME: 'pickup-time',
    PICKUP_TZ: 'pickup-tz',
    PICKUP_LOCATION: 'pickup-location',
    DROPOFF_TIME: 'dropoff-time',
    DROPOFF_TZ: 'dropoff-tz',
    DROPOFF_LOCATION: 'dropoff-location',
    DRIVER_NAME: 'driver-name',
    DRIVER_PHONE_NUMBER: 'driver-phone-number',
    DISPATCHER_PHONE_NUMBER: 'driver-dispatcher-phone-number',
    TRACKING_FREQUENCY: 'tracking-frequency',
    TRACKING_FREQUENCY_DURATION: 'tracking-frequency-duration',
}

export const AdminCreateModal = (props) => {
    const {user} = useBrokerAuth()
    const toast = useToast()
    const {
        placesAutocompleteService,
        placesService,
        getPlacePredictions,
    } = usePlacesService({});

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            rememberMe: false
        },
        onSubmit: async (values, actions) => {
            const source = values[FIELD_IDS.PICKUP_LOCATION]
            const destination = values[FIELD_IDS.DROPOFF_LOCATION]
            const phone_number = `+1${values[FIELD_IDS.DRIVER_PHONE_NUMBER].replace(/-/g, "")}`;
            const pickupTz = getTimezone(values[FIELD_IDS.PICKUP_LOCATION])
            const dropoffTz = getTimezone(values[FIELD_IDS.DROPOFF_LOCATION])
            const pickupParsed = mom.tz(values[FIELD_IDS.PICKUP_TIME], moment.defaultFormat, pickupTz).toISOString()
            const dropoffParsed = mom.tz(values[FIELD_IDS.DROPOFF_TIME], moment.defaultFormat, dropoffTz).toISOString()
            const carrier = {
                name: values[FIELD_IDS.DRIVER_NAME],
                phone_number: phone_number,
                dispatcher_phone_number: values[FIELD_IDS.DISPATCHER_PHONE_NUMBER] ? `+1${values[FIELD_IDS.DISPATCHER_PHONE_NUMBER].replace(/-/g, "")}`: null,
            }
            const load = {
                external_reference_id: values[FIELD_IDS.LOAD_ID].trim(),
                pickup: {
                    lat: source.lat,
                    long: source.long,
                    time: pickupParsed,
                    name: source.name,
                },
                dropoff: {
                    lat: destination.lat,
                    long: destination.long,
                    time: dropoffParsed,
                    name: destination.name,
                },
                carrier: carrier,
                company: user,
                status: 0,
            }

            actions.setSubmitting(true)
            await post('/loads', {
                load: {
                    ...load,
                    locations: [load.pickup, load.dropoff],
                    tracking_config: {
                        minutes_between: fields['Tracking-frequency'] * 60,
                    }
                },
                userEmail: values[FIELD_IDS.SENDER_EMAIL],
            })
            actions.setSubmitting(false)
            actions.resetForm()
            toast({
                title: 'Load created.',
                description: "We've created the load",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        }
    });
    const { shipments, isOpen, onClose, location } = props;
    const [fields, setFields] = useState({
        'load-id': '',
        'Tracking-frequency': 2,
        'Tracking-frequency-period': 'hours',
    })
    const pastLocations = useMemo(() => {
        if (shipments) {
            const mapped = shipments.map((shipment) => [shipment.origin, shipment.destination]).flat()
            return [...new Map(mapped.map(v => [v.name, v])).values()]
        }

        return [];
    }, [shipments])

    console.log(formik.values[FIELD_IDS.DROPOFF_LOCATION])
    return(
        <Container isOpen={isOpen} onClose={onClose} maxWidth={'80vw'} size={'4xl'} padding={10} boxShadow={"xl"}>
                    <Stack spacing={10} color={'black'}>
                        <Stack>
                            <Heading
                                color={'black'}
                                fontSize={'l'}
                                fontFamily={'body'}>
                                Load
                            </Heading>
                            <Divider />
                            <HStack spacing={5} justifyContent={'space-between'}>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.LOAD_ID}>Load ID</FormLabel>
                                    <Input
                                        id={FIELD_IDS.LOAD_ID}
                                        onChange={formik.handleChange}
                                        value={formik.values[FIELD_IDS.LOAD_ID]}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.SENDER_EMAIL}>Sender email</FormLabel>
                                    <Input
                                        id={FIELD_IDS.SENDER_EMAIL}
                                        onChange={formik.handleChange}
                                        value={formik.values[FIELD_IDS.SENDER_EMAIL]}
                                    />
                                </FormControl>
                            </HStack>
                        </Stack>


                        {/*pickup*/}
                        <Stack>
                            <Heading
                                color={'black'}
                                fontSize={'l'}
                                fontFamily={'body'}>
                                Pickup
                            </Heading>
                            <Divider />
                            <HStack spacing={5} justifyContent={'space-between'}>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.PICKUP_LOCATION}>Location</FormLabel>
                                    <LocationSearch
                                        autocomplete={placesAutocompleteService}
                                        places={placesService}
                                        pastLocations={pastLocations}
                                        setLocation={(value) => formik.setFieldValue(FIELD_IDS.PICKUP_LOCATION,  value)}/>
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.PICKUP_TIME}>Time</FormLabel>
                                    <InputGroup >
                                        <Input
                                            type={'datetime-local'}
                                            id={FIELD_IDS.PICKUP_TIME}
                                            onChange={formik.handleChange}
                                            value={formik.values[FIELD_IDS.PICKUP_TIME]}
                                        />
                                        <InputRightAddon padding={0}>
                                            <Text padding={5} w={"200px"}>
                                                {getTimezone(formik.values[FIELD_IDS.PICKUP_LOCATION])}
                                            </Text>
                                        </InputRightAddon>
                                    </InputGroup>
                                </FormControl>
                            </HStack>
                        </Stack>

                        {/*dropoff*/}
                        <Stack>
                            <Heading
                                color={'black'}
                                fontSize={'l'}
                                fontFamily={'body'}>
                                Dropoff
                            </Heading>
                            <Divider />
                            <HStack spacing={5} justifyContent={'space-between'}>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DROPOFF_LOCATION}>Location</FormLabel>
                                    <LocationSearch
                                        autocomplete={placesAutocompleteService}
                                        places={placesService}
                                        pastLocations={pastLocations}
                                        setLocation={(value) => formik.setFieldValue(FIELD_IDS.DROPOFF_LOCATION,  value)}/>
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DROPOFF_TIME}>Time</FormLabel>
                                    <InputGroup>
                                        <Input
                                            type={'datetime-local'}
                                            id={FIELD_IDS.DROPOFF_TIME}
                                            onChange={formik.handleChange}
                                            value={formik.values[FIELD_IDS.DROPOFF_TIME]}
                                        />
                                        <InputRightAddon padding="0px" children={
                                            <Text padding={5} w={"200px"}>
                                                {getTimezone(formik.values[FIELD_IDS.DROPOFF_LOCATION])}
                                            </Text>
                                            //     <Select maxW={"170px"} defaultValue={getTimezone(formik.values[FIELD_IDS.DROPOFF_LOCATION])} placeholder='Select timezome' id={FIELD_IDS.DROPOFF_TZ} value={formik.values[FIELD_IDS.DROPOFF_TZ]}>
                                            //         {moment.tz.zonesForCountry("US").map((tz) => {
                                            //             return <option value={tz}>{tz}</option>
                                            //         })}
                                            // </Select>
                                        } />
                                    </InputGroup>
                                </FormControl>
                            </HStack>
                        </Stack>


                        {/*carrier*/}
                        <Stack>
                            <Heading
                                color={'black'}
                                fontSize={'l'}
                                fontFamily={'body'}>
                                Carrier
                            </Heading>
                            <Divider />
                            <HStack spacing={5} justifyContent={'space-between'}>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DRIVER_NAME}>Name</FormLabel>
                                    <Input
                                        id={FIELD_IDS.DRIVER_NAME}
                                        onChange={formik.handleChange}
                                        value={formik.values[FIELD_IDS.DRIVER_NAME]}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DRIVER_PHONE_NUMBER}>Phone number</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon>+1</InputLeftAddon>
                                        <Input
                                            type={'tel'}
                                            id={FIELD_IDS.DRIVER_PHONE_NUMBER}
                                            value={formik.values[FIELD_IDS.DRIVER_PHONE_NUMBER]}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DISPATCHER_PHONE_NUMBER}>Dispatcher phone number</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon>+1</InputLeftAddon>
                                        <Input
                                            type={'tel'}
                                            id={FIELD_IDS.DISPATCHER_PHONE_NUMBER}
                                            value={formik.values[FIELD_IDS.DISPATCHER_PHONE_NUMBER]}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>
                            </HStack>
                        </Stack>


                        {/*tracking-frequency*/}
                        <Stack>
                            <Heading
                                color={'black'}
                                fontSize={'l'}
                                fontFamily={'body'}>
                                Frequency
                            </Heading>
                            <Divider />
                            <HStack spacing={5} justifyContent={'space-between'}>
                                <FormControl>
                                    <FormLabel fontSize={'sm'} color={'black'} htmlFor={FIELD_IDS.DRIVER_NAME}>Name</FormLabel>
                                    <InputGroup >
                                        <Input type='number' placeholder={60}
                                               maxW={"60px"}
                                               id={FIELD_IDS.TRACKING_FREQUENCY}
                                               value={formik.values[FIELD_IDS.TRACKING_FREQUENCY]}
                                               onChange={formik.handleChange} />
                                        <InputRightAddon padding="0px" children={  <Select placeholder='Select frequency' id={FIELD_IDS.TRACKING_FREQUENCY_DURATION} value={'hours'} maxWidth='100%'>
                                            <option value='hours'>Hours</option>
                                        </Select>} />

                                    </InputGroup>
                                </FormControl>
                            </HStack>
                        </Stack>
                    </Stack>

            <HStack padding={10} align={"center"} justify={"flex-end"}>
                <Button color={'white'} bg={'black'} onClick={formik.submitForm}>
                    Submit
                </Button>
            </HStack>
        </Container>
    );
}

