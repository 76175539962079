import * as React from "react";
import {useLocation, Navigate} from "react-router-dom";
import {useEffect} from "react";
import axios from "axios";
import {BASE_URL, fetcher} from "../requests/axios";
import {H} from "highlight.run";

let AuthContext = React.createContext(null);

const formFieldsToServer = (formFields) => ({
    first_name: '-',
    last_name: '-',
    company: {
        phone_number: formFields.phoneNumber,
        name: formFields.brokerName,
        type: 1,
    },
    email: formFields.email,
    password: formFields.password,
})

export function BrokerAuthProvider({ children }) {
        const [token, setToken] = React.useState(null)
        const [company, setCompany] = React.useState(null);
        const [user, setUser] = React.useState(null);
        const [isFetching, setIsFetching] = React.useState(true)
        const getUser = React.useCallback(() => {
            return fetcher('/user').then((res) => {
                setUser(res.email)
                setCompany(res.company)
            }).finally(() => setIsFetching(false))
        }, [setCompany, setIsFetching])
        useEffect(() => {
            getUser()
        }, [getUser])

    useEffect(() => {
       if (user) {
            H.identify(user);
       }
    }, [user])

    let createUser = async (formFields, callback) => {
            const res = await axios.post(`${BASE_URL}/unauth/users`, formFieldsToServer(formFields))
            if (res.status != 200) {
                return res.status
            }

        if(res.status === 200) {
            localStorage.setItem('token', JSON.stringify(res.data))
            getUser().then(() => callback())
        }
    }

    let signin = (userName, password, callback) => {
        axios
            .post(`${BASE_URL}/unauth/login`, { email: userName, password })
            .then((res) => {
                if(res.status === 200) {
                    localStorage.setItem('token', JSON.stringify(res.data))
                    getUser().then(() => callback())
                }
            })
            .catch((err) => {
                console.log(err.response.data.detail.toString());
            });
    };

    let signout = (callback) => {
        fetch('https://livetrucks-be.herokuapp.com/api/logout', {
            method: 'POST',
            credentials: 'include',
        })
        setCompany(null)
        localStorage.removeItem('token')
        callback()
    };

    let value = { user: company, isFetching, signin, signout, createUser, actualUser: user, };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useBrokerAuth() {
    return React.useContext(AuthContext);
}

export function RequireBrokerAuth({ children }) {
    let auth = useBrokerAuth();
    let location = useLocation();
    if (auth.isFetching){
        return null;
    }

    if (!auth.user) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}
