
import {Form, Formik, Field} from 'formik';
import {
    Box,
    Button, Center, chakra, Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel, GridItem,
    Heading,
    Input, SimpleGrid, Stack, Text,
    useColorModeValue, useToast
} from "@chakra-ui/react";
import {useBrokerAuth} from "../util/auth/broker-auth";
import {useNavigate} from "react-router-dom";
import React from "react";
import amplitude from 'amplitude-js';
import WithSpeechBubbles from "./testimonials";


export function Refer(){
    const toast = useToast()
    const {user} = useBrokerAuth();
    return (
        <Box>
        <Box px={8} py={24} mx="auto" backgroundColor={'white'}>
            <SimpleGrid
                alignItems="center"
                w={{ base: "full", xl: 11 / 12 }}
                columns={{ base: 1, lg: 11 }}
                gap={{ base: 0, lg: 24 }}
                mx="auto"
            >
                <GridItem
                    colSpan={{ base: "auto", lg: 7 }}
                    textAlign={{ base: "center", lg: "left" }}
                >
                    <chakra.h1
                        mb={4}
                        fontSize={{ base: "3xl", lg: "4xl" }}
                        fontWeight="bold"
                        lineHeight={{ base: "shorter", lg: "none" }}
                        color={useColorModeValue("gray.900", "gray.200")}
                        letterSpacing={{ base: "normal", lg: "tight" }}
                    >
                        Love using LiveTrucks?
                    </chakra.h1>
                    <chakra.p
                        mb={{ base: 10, lg: 4 }}
                        fontSize={{ base: "lg", lg: "xl" }}
                        color="black"
                        letterSpacing="wider"
                    >
                    <br />
                    <br />
                    <br />
                       Earn <b> $100 </b> for each referral.
                       <br />
                       <br />
                       Your referral gets 1 month free of LiveTrucks
                    </chakra.p>
                </GridItem>
                <GridItem colSpan={{ base: "auto", lg: 4 }}>
                    <Box mb={6} rounded="lg" shadow="xl" backgroundColor={'white'}>
                        <SimpleGrid
                            columns={1}
                            px={6}
                            py={4}
                            spacing={4}
                            borderBottom="solid 1px"
                            borderColor={useColorModeValue("gray.200", "gray.700")}
                        >
                            <Formik
                                initialValues={{ yourEmail: '', brokerName: '', brokerEmail: '', phoneNumber: ''}}
                                onSubmit={(values, actions) => {
                                    amplitude.getInstance().logEvent("user-referral", {
                                        values,
                                        user,
                                    });
                                    toast({
                                        title: 'Referral Successful',
                                        description: "We will reach out to you within 24 hours",
                                        status: 'success',
                                        duration: 9000,
                                        isClosable: true,
                                    })
                                    actions.setSubmitting(false)
                                }}
                            >
                                {(props) => (
                                    <Form id="referral-form">
                                        <Stack spacing={5} padding="20px">
                                            <Heading size={"md"}>
                                                Referral details
                                            </Heading>
                                            <Text size={"md"}>
                                                Please enter the details of the broker you want to refer
                                            </Text>
                                            <Divider />
                                            <Field name='brokerName'>
                                                {({ field, form }) => (
                                                    <FormControl isInvalid={form.errors.brokerName && form.touched.brokerName}>
                                                        <FormLabel htmlFor='brokerName'>Name</FormLabel>
                                                        <Input {...field} id='brokerName' placeholder='Reliable Logistics LLC' />
                                                        <FormErrorMessage>{form.errors.brokerName}</FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name='referralEmail'>
                                                {({ field, form }) => (
                                                    <FormControl isInvalid={form.errors.referralEmail && form.touched.referralEmail}>
                                                        <FormLabel htmlFor='referralEmail'>Email</FormLabel>
                                                        <Input {...field} id='referralEmail' placeholder='hello@youareawesome.com' />
                                                        <FormErrorMessage>{form.errors.referralEmail}</FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Field name='referralPhoneNumber'>
                                                {({ field, form }) => (
                                                    <FormControl isInvalid={form.errors.phoneNumber && form.touched.phoneNumber}>
                                                        <FormLabel htmlFor='phoneNumber'>Phone number</FormLabel>
                                                        <Input {...field} id='phoneNumber' placeholder='+1-469-444-4444' />
                                                        <FormErrorMessage>{form.errors.phoneNumber}</FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Button
                                                mt={4}
                                                colorScheme='teal'
                                                isLoading={props.isSubmitting}
                                                type='submit'
                                            >
                                                Submit
                                            </Button>
                                        </Stack>
                                    </Form>

                                )}
                            </Formik>
                        </SimpleGrid>
                    </Box>
                </GridItem>
            </SimpleGrid>
        </Box>
        </Box>
    );
};
