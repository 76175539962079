import {DetailField, ShipmentDetailsPage} from "./shipment-details";
import {Fragment, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Checkbox,
    Container, createIcon,
    Divider,
    Flex,
    Heading,
    HStack, Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Link,
    List,
    ListIcon,
    ListItem,
    Progress,
    Select,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import {CreateShipmentModalRedesigned} from "./create-shipment-modal";
import {useNavigate} from "react-router-dom";
import {LoadStatus} from '../constants'
import {Load} from "../models";
import {fetcher} from "../util/requests/axios";
import {useBrokerAuth} from "../util/auth/broker-auth";
import {
    AiOutlineArrowRight,
    FaMapMarker,
    FaMapMarkerAlt,
    MdClear,
    MdSearch,
    RiMapPinFill,
    RiMapPinLine
} from "react-icons/all";
import Fuse from "fuse.js";
import {MdTripOrigin} from "react-icons/md";

export const SortByFields = Object.freeze({
    'load-id': 'Load ID',
    'updated_at': 'Last update',
    'pickup': 'Pickup date',
})

const loadStatusToBarsFilled = {
    0: 0,
    1: 1,
    4: 2,
    2: 3,
    3: 4,
}

const sorter = (loads, filter) => {
    switch (filter) {
        case 'load-id':
            return loads.sort((a, b) => b.loadReference - a.loadReference)
        case 'pickup':
            return loads.sort((a, b) => new Date(b.origin.time) - new Date(a.origin.time))
        case 'updated_at':
            return loads.sort((a, b) => {
                if (b.lastLocation == null && a.lastLocation == null) {
                    return 0
                }

                if (b.lastLocation?.recordedAt != null && a.lastLocation?.recordedAt != null) {
                    return b.lastLocation.recordedAt - a.lastLocation.recordedAt
                }

                if (a.lastLocation != null) {
                    return -1
                }

                return 1
            })
        default:
            return loads
    }
}
export const Broker = () => {
    const {user} = useBrokerAuth()
    const [statusFilter, setStatusFilter] = useState(0);
    const [pickupDateFilter, setPickupDateFilter] = useState("")
    const [toggleExceptionFilter, setToggleExceptionFilter] = useState(false);
    const [searchTerm, setSearchTerm] = useState("")
    const [sortBy, setSortBy] = useState(null);

    let navigate = useNavigate();
    const [shipments, setShipments] = useState([])
    const {isOpen, onOpen, onClose} = useDisclosure()
    const fetchAndSetShipments = useCallback(() => {
        fetcher(`/loads`).then((res) => {
                res = res.map((shipment) => new Load(shipment))
                setShipments(res)
        })
    }, [setShipments])

    const options = {
        // isCaseSensitive: false,
        // includeScore: false,
        // shouldSort: true,
        // includeMatches: false,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
        // threshold: 0.6,
        // distance: 100,
        // useExtendedSearch: false,
        // ignoreLocation: false,
        // ignoreFieldNorm: false,
        // fieldNormWeight: 1,
        keys: [
            "loadReference",
            "origin.name",
            "destination.name",
        ],
        threshold: 0.1,
    };

    const fuse = useMemo(() => new Fuse(shipments, options), [shipments])
    const filteredLoads = useMemo(() =>
    {
        let filtered = shipments;
        if (searchTerm) {
            filtered = fuse.search(searchTerm).map((item) => item.item)
        }

        return filtered.filter((load) => {
            const status = statusFilter != 0 ? load.status == statusFilter : true;
            const checked = toggleExceptionFilter ? load.exceptions.length !== 0 : true;
            let dateFilter = true;
            if (pickupDateFilter) {
                const day = new Date(load.origin.time)
                day.setHours(0, 0, 0, 0)
                const filterDay = new Date(pickupDateFilter)
                filterDay.setUTCDate(filterDay.getUTCDate() + 1)
                filterDay.setHours(0, 0, 0, 0)
                dateFilter = day.valueOf() === filterDay.valueOf()
            }

            return status && checked && dateFilter
        })
        }, [shipments, statusFilter, toggleExceptionFilter, pickupDateFilter, searchTerm])

    const sorted = useMemo(() => {
        return sorter(filteredLoads, sortBy)
    }, [filteredLoads, sortBy])

    const onModalClose = useCallback(() => {
        fetchAndSetShipments()
        onClose()
    }, [onClose])

    useEffect(() => {
        if (user != null) {
            fetchAndSetShipments()
        }
    }, [user])


    return(
        <Container py={6} maxW={{base: '90vw', lg: '80vw'}} alignContent={"center"} marginTop="20px" fontFamily="body">
            <Flex alignItems={'start'} justifyContent={'space-between'}>
                <Stack w={"15vw"} pt={20} spacing={10} display={{base: 'none', lg: 'block'}} marginRight={{
                    base: 0,
                    lg: 20,
                }}>
                <Box
                    marginRight="40px"
                    display={{base: 'none', lg: 'block'}}
                    minWidth="15vw"
                    boxShadow={'xl'}
                    rounded={'md'}
                    overflow={'hidden'}>
                    <HStack justify={"space-between"} padding={"20px"}>
                    <Heading textAlign="left"
                             color={useColorModeValue('black', 'black')}
                             fontSize={'l'}
                             fontFamily={'body'}>
                        Filters
                    </Heading>
                        <Button size="sm" onClick={() => {
                            setStatusFilter(0)
                            setPickupDateFilter("")
                            setToggleExceptionFilter(false)
                        }}>
                            Clear
                        </Button>
                    </HStack>
                    <Divider />
                    <Stack padding="20px" spacing={5} justifyContent="space-between" bg={useColorModeValue('gray.50', 'gray.700')}>
                        <Stack>
                            <Heading fontSize="m">
                                Status
                            </Heading>
                            <Select value={statusFilter} onChange={(event) => setStatusFilter(event.target.value)} placeholder="Select Status">
                                {Object.entries({...LoadStatus, 0: "ALL", }).map(([statusType, statusValue]) => {
                                    return (
                                        <option value={statusType}>
                                            {statusValue}
                                        </option>
                                    )
                                })}
                            </Select>
                        </Stack>
                        <Stack>
                            <Heading fontSize="m">
                                Pickup
                            </Heading>
                            <Input  type="date" value={pickupDateFilter} onChange={(event) => setPickupDateFilter(event.target.value)}/>
                        </Stack>
                        <Stack>
                            <Heading fontSize="m">
                                Toggles
                            </Heading>
                        <Checkbox onChange={(event) => setToggleExceptionFilter(event.target.checked)} isChecked={toggleExceptionFilter}>
                            Has exception
                        </Checkbox>
                        </Stack>
                    </Stack>
                </Box>
                    <Box
                        marginRight="40px"
                        display={{base: 'none', lg: 'block'}}
                        minWidth="15vw"
                        boxShadow={'xl'}
                        rounded={'md'}
                        overflow={'hidden'}>
                        <HStack justify={"space-between"} padding={"20px"}>
                            <Heading textAlign="left"
                                     color={useColorModeValue('black', 'black')}
                                     fontSize={'l'}
                                     fontFamily={'body'}>
                                Sort
                            </Heading>
                        </HStack>
                        <Divider />
                        <Stack padding="20px" spacing={5} justifyContent="space-between" bg={useColorModeValue('gray.50', 'gray.700')}>
                            <Stack>
                                <Heading fontSize="m">
                                    Sort by
                                </Heading>
                                <Select onChange={(event) => setSortBy(event.target.value)} value={sortBy} placeholder={"Select"}>
                                    {Object.entries(SortByFields).map(([fieldName, fieldValue]) => {
                                        return (
                                            <option value={fieldName}>
                                                {fieldValue}
                                            </option>
                                        )
                                    })}
                                </Select>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box
                        marginRight="40px"
                        display={{base: 'none', lg: 'block'}}
                        minWidth="15vw"
                        boxShadow={'xl'}
                        rounded={'md'}
                        overflow={'hidden'}>
                        <HStack justify={"space-between"} padding={"20px"}>
                            <Heading textAlign="left"
                                     color={useColorModeValue('black', 'black')}
                                     fontSize={'l'}
                                     fontFamily={'body'}>
                                Get $100 bonus per referral
                            </Heading>
                        </HStack>
                        <Divider />
                        <Stack padding="20px" spacing={5} justifyContent="space-between" bg={useColorModeValue('gray.50', 'gray.700')}>
                            <Text> Love using LiveTrucks? </Text>
                            <Text> Get $100 cash bonus per referral and give your friends 1 month of free LiveTrucks </Text>
                            <Button backgroundColor="blackAlpha.800" color="white" onClick={() => navigate(`../refer`)} >Refer now</Button>
                        </Stack>
                    </Box>
                </Stack>
            <Stack width='full' spacing={10}>
                <HStack justifyContent='space-between'>
                    <Heading fontSize={'xl'}>
                        Loads
                    </Heading>
                    <Button color={'white'} bg={'black'} onClick={onOpen}>
                        Create new
                    </Button>
                </HStack>
                <InputGroup>
                    <InputLeftElement
                        pointerEvents='none'
                        color='gray.300'
                        fontSize='1.2em'
                        children={<MdSearch />}
                    />
                    <Input placeholder='Search by load ID or pickup or destination' onChange={(event) => setSearchTerm(event.target.value)} value={searchTerm}/>
                    <InputRightElement children={<MdClear onClick={() => setSearchTerm("")}/>} />
                </InputGroup>
                {/*<AutoComplete rollNavigation>*/}
                {/*    <AutoCompleteInput variant="filled" placeholder="Search..." autoFocus />*/}
                {/*    <AutoCompleteList>*/}
                {/*        {searchOptions.map((option, oid) => (*/}
                {/*            <AutoCompleteItem*/}
                {/*                key={`option-${oid}`}*/}
                {/*                value={option.value}*/}
                {/*                textTransform="capitalize"*/}
                {/*            >*/}
                {/*                <Stack>*/}
                {/*                    <Text>*/}
                {/*                    {option.shipment.loadReference}*/}
                {/*                    </Text>*/}
                {/*                    <Divider />*/}
                {/*                <HStack align={"center"} space={3}>*/}
                {/*                    <Stack>*/}
                {/*                        <Heading size={"sm"}>*/}
                {/*                            Origin*/}
                {/*                        </Heading>*/}
                {/*                    <Text b>*/}
                {/*                        {option.shipment.origin.name}*/}
                {/*                    </Text>*/}
                {/*                    </Stack>*/}
                {/*                    <Stack>*/}
                {/*                        <Heading size={"sm"}>*/}
                {/*                            Destination*/}
                {/*                        </Heading>*/}
                {/*                    <Text b>*/}
                {/*                        {option.shipment.destination.name}*/}
                {/*                    </Text>*/}
                {/*                    </Stack>*/}
                {/*                </HStack>*/}
                {/*                </Stack>*/}
                {/*            </AutoCompleteItem>*/}
                {/*        ))}*/}
                {/*    </AutoCompleteList>*/}
                {/*</AutoComplete>*/}
                <Stack spacing={10}>
            {sorted.length != 0 ? sorted.map((shipment) => {
            return <ShipmentDashboardButton  shipment={shipment}/>
        }) : <NoLoadsNeedHelp />}
            </Stack>
            </Stack>
            </Flex>
            <CreateShipmentModalRedesigned isOpen={isOpen} onOpen={onOpen} onClose={onModalClose} shipments={shipments}/>
        </Container>
    )
}

const NoLoadsNeedHelp = (props) => {
    return <Box
        marginTop={"20px"}
        as="button"
        w={'full'}
        boxShadow={'l'}
        rounded={'md'}
        overflow={'hidden'}>
        <Stack>
        <Heading>
            Need help?
        </Heading>
            <Text>
                <Link href={'https://calendly.com/livetrucks-demo/demo'}> Book a free demo </Link>
            </Text>
        </Stack>
    </Box>
}

export const ShipmentDashboardStepBar = (props) => {
    const {load} = props;

    const calculateProgress = (number) => {
        return Math.floor(loadStatusToBarsFilled[load.status] / number) * 100
    }

    return (
        <VStack maxW={"400px"} w={"100%"} align={"flex-start"} spacing={5}>
            <VStack align={"flex-start"}>
            <Heading size={"md"}>
                {load.displayStatus}
            </Heading>
            {load.lastLocation ?
                <VStack align={"flex-start"} spacing={1}>
            <Text size={"md"} color={"black"} textAlign={"left"}>
                {load.lastLocation.location.name}
            </Text>
                    <Text size={"sm"} color={"blackAlpha.600"} textAlign={"left"}>
                    {new Date(load.lastLocation.recordedAt).toLocaleString()}
                </Text>
                </VStack>: null}
            </VStack>
        <HStack maxW={"400px"} w={"100%"}>
        <Progress w="100%" value={calculateProgress(1)} />
            <Progress w="100%" value={calculateProgress(2)} />
            <Progress w="100%" value={calculateProgress(3)} />
            <Progress w="100%" value={calculateProgress(4)} />
        </HStack>

        </VStack>
    )
}

const ShipmentDashboardButton = (props) => {
                const {shipment} = props;
    let navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <div>
            <Box
                onClick={() => navigate(`shipments/${shipment.id}`)}
                w={'full'}
                as={"button"}
                boxShadow={'lg'}
                rounded={'md'}
            >
                <VStack spacing={0} alignItems={'start'}>
                    <Heading
                        textAlign="left"
                        padding="20px"
                        color={useColorModeValue('black', 'black')}
                        fontSize={'l'}
                        fontFamily={'body'}>
                        {shipment.loadReference}
                    </Heading>
                    {shipment.exceptions.length ? <Alert status='error'>
                        <AlertIcon />
                        <AlertTitle>Load needs attention!</AlertTitle>
                        <AlertDescription>{shipment.exceptions[0].exceptionMessage}</AlertDescription>
                    </Alert>: null}
                </VStack>
                <Divider />
                <Stack spacing={5} padding="20px" bg={useColorModeValue('gray.50', 'gray.700')} justifyContent={"space-between"} direction={{base: "column", lg: "row"}}>
                    <ShipmentDashboardStepBar load={shipment}/>
                <HStack spacing={2} columns={{base: 2, lg: 4}} textAlign={"left"} width={"80%"} justifyContent={{base: "flex-start", md: "flex-end"}}>
                    <List>
                        <ListItem style={{
                            paddingLeft: "20px",
                            paddingBottom: "20px",
                            position: "relative",
                        }}
                                  _before={{
                                      borderRadius: "50%",
                                      content: `""`,
                                      position: "absolute",
                                      backgroundColor: "currentColor",
                                      top: "7px",
                                      left: 0,
                                      width: "9px",
                                      height: "9px",
                                  }}
                                  _after={{
                            content: `""`,
                            position: "absolute",
                            backgroundColor: "currentColor",
                            top: "18px",
                            left: "4px",
                            height: "calc(100% - 13px)",
                            width: "1px",
                        }}>
                            <VStack align={"start"}>
                                <Text>
                            {shipment.origin.name}
                                </Text>
                                <Text>
                                {new Date(shipment.origin.time).toLocaleString()}
                                </Text>
                            </VStack>
                        </ListItem>
                        <ListItem style={{
                            paddingLeft: "20px",
                            position: "relative",
                        }} _before={{
                            content: `""`,
                            position: "absolute",
                            backgroundColor: "currentColor",
                            top: "7px",
                            left: 0,
                            width: "9px",
                            height: "9px",
                        }}>
                            <VStack align={"start"}>
                                <Text>
                                    {shipment.destination.name}
                                </Text>
                                {shipment.destination.time ?
                                <Text>
                                    {new Date(shipment.destination.time).toLocaleString()}
                                </Text>: null}
                            </VStack>

                        </ListItem>
                    </List>
                        {/*/!*<DetailField fieldName="Status" fieldValue={[shipment.displayStatus]} />*!/*/}
                        {/*<DetailField fieldName="Pickup" fieldValue={[shipment.origin.name]} />*/}
                        {/*<DetailField fieldName="Destination" fieldValue={[shipment.destination.name]} />*/}
                        {/*<DetailField fieldName="Last tracked location" fieldValue={[shipment.lastLocation?.location?.name || '-']} />*/}
                </HStack>
                </Stack>
            </Box>
        </div>
            );
}

const PickupIcon = () => {
    return (
        <Icon color='red.500'>
            <path d="M12.1658 8.93977C11.6418 10.0015 10.9325 11.0601 10.2058 12.01C9.48132 12.957 8.75442 13.7768 8.20768 14.3605C8.13503 14.438 8.06566 14.5113 8 14.5801C7.93434 14.5113 7.86497 14.438 7.79232 14.3605C7.24558 13.7768 6.51868 12.957 5.79425 12.01C5.06754 11.0601 4.35825 10.0015 3.83423 8.93977C3.3048 7.86708 3 6.86191 3 6C3 3.23858 5.23858 1 8 1C10.7614 1 13 3.23858 13 6C13 6.86191 12.6952 7.86708 12.1658 8.93977ZM8 16C8 16 14 10.3137 14 6C14 2.68629 11.3137 0 8 0C4.68629 0 2 2.68629 2 6C2 10.3137 8 16 8 16Z" fill="black"/>
            <path d="M8 8C6.89543 8 6 7.10457 6 6C6 4.89543 6.89543 4 8 4C9.10457 4 10 4.89543 10 6C10 7.10457 9.10457 8 8 8ZM8 9C9.65685 9 11 7.65685 11 6C11 4.34315 9.65685 3 8 3C6.34315 3 5 4.34315 5 6C5 7.65685 6.34315 9 8 9Z" fill="black"/>
        </Icon>
    )
}
