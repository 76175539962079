import { ReactNode } from 'react';
import {
    Box,
    Flex,
    Heading,
    Text,
    Stack,
    Container,
    Avatar,
    useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';

const Testimonial = ({ children }: { children: ReactNode }) => {
    return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
    return (
        <Stack
            bg={'blackAlpha.100'}
            boxShadow={'lg'}
            p={8}
            rounded={'xl'}
            align={'center'}
            pos={'relative'}
            _after={{
                content: `""`,
                w: 0,
                h: 0,
                borderLeft: 'solid transparent',
                borderLeftWidth: 16,
                borderRight: 'solid transparent',
                borderRightWidth: 16,
                borderTop: 'solid',
                borderTopWidth: 16,
                borderTopColor:  'blackAlpha.100',
                pos: 'absolute',
                bottom: '-16px',
                left: '50%',
                transform: 'translateX(-50%)',
            }}>
            {children}
        </Stack>
    );
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
    return (
        <Heading as={'h3'} fontSize={'xl'}>
            {children}
        </Heading>
    );
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
    return (
        <Text
            textAlign={'center'}
            color={useColorModeValue('gray.600', 'gray.400')}
            fontSize={'md'}>
            {children}
        </Text>
    );
};

const TestimonialAvatar = ({
                               src,
                               name,
                               title,
                           }: {
    src: string;
    name: string;
    title: string;
}) => {
    return (
        <Flex align={'center'} mt={8} direction={'column'}>
            <Avatar src={src} mb={2}  size='xl'/>
            <Stack spacing={-1} align={'center'}>
                <Text fontWeight={600}>{name}</Text>
                <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
                    {title}
                </Text>
            </Stack>
        </Flex>
    );
};

export default function WithSpeechBubbles() {
    return (
        <Box w={'100vw'} backgroundColor={'white'}>
            <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
                <Stack spacing={0} align={'center'}>
                    <Heading>Our Clients Speak</Heading>
                </Stack>
                <Stack
                    direction={{ base: 'column', lg: 'row' }}
                    spacing={{ base: 10, md: 4, lg: 10 }}>
                    <Testimonial>
                        <TestimonialContent>
                            {/* <TestimonialHeading>Time saving</TestimonialHeading> */}
                            <TestimonialText>
                            We have been using LiveTrucks and it has been unlike any other comparable service.  It is extremely easy to use.  It is saving us multiple hours per week in check calls and being able to provide a tracking link to customers has stopped our receivers from having to call us throughout the night.
                            </TestimonialText>
                        </TestimonialContent>
                        <TestimonialAvatar
                            src={'ChadZizelman.jpg'}
                            name={'Chad Zizelman'}
                            title={'GM, Iggy Transportation'}
                        />
                    </Testimonial>
                    {/* <Testimonial>
                        <TestimonialContent>
                            <TestimonialHeading>Time saving</TestimonialHeading>
                            <TestimonialText>
                                I used to spend 2 hours a day doing check calls. Now I spend 5 minutes
                            </TestimonialText>
                        </TestimonialContent>
                        <TestimonialAvatar
                            src={'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80'}
                            name={'Loretta C.'}
                            title={'Head of Logistics, Efficent Brokers LLC.'}
                        />
                    </Testimonial>
                    <Testimonial>
                        <TestimonialContent>
                            <TestimonialHeading>Carriers love me</TestimonialHeading>
                            <TestimonialText>
                                My carriers thank me for using LiveTrucks texts instead of calling them repeatedly for updates
                            </TestimonialText>
                        </TestimonialContent>
                        <TestimonialAvatar
                            src={
                                'person2.jpg'
                            }
                            name={'Steve M.'}
                            title={'Freight Broker, Phoenix AZ'}
                        />
                    </Testimonial> */}
                </Stack>
            </Container>
        </Box>
    );
}
