import {
    Box,
    Button,
    Container,
    Flex, FormControl, FormErrorMessage, FormLabel,
    Heading, HStack, Input, ListItem,
    Radio,
    RadioGroup, Select,
    Stack,
    Text, UnorderedList,
    useColorModeValue, useToast
} from "@chakra-ui/react";
import {Step, Steps, useSteps} from "chakra-ui-steps";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useCarrierAuth} from "../util/auth/carrier-auth";
import {BASE_URL, fetcher, post} from "../util/requests/carrier-axios";
import {useBrokerAuth} from "../util/auth/broker-auth";
import {Field, Form, Formik} from "formik";
import amplitude from "amplitude-js";
const content = (
    <Flex py={4}>
        Hi
    </Flex>
);

const VALUES = {
    KEEPTRUCKIN: 1,
    ELD360: 2,
    SAMSARA: 3,
}


export const ConnectELD = () => {
    const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
        initialStep: 0,
    });

    const [provider, setProvider] = useState(null);
    console.log(provider)
    const {user} = useCarrierAuth();
    let [searchParams,] = useSearchParams();
    useEffect(() => {
        if (searchParams.get('type') !== "keeptruckin") {
            return
        }

        setProvider(VALUES.KEEPTRUCKIN)
    }, [searchParams])

    const navig = (value) => {
        const url = "https://account.gomotive.com/oauth/authorize?client_id=046d82db65c4d6909b550fb61168249803498b710424e720dc30ab6101619e5b&redirect_uri=https://livetrucks.io/carrier/connect?type=keeptruckin&response_type=code&scope=%20%20%20%20%20%20vehicles.read+locations.vehicle_locations_single+locations.vehicle_locations_list"
        setProvider(value)
        if (value == VALUES.KEEPTRUCKIN) {
            window.location.replace(url)
        }
    }

    const getProviderSteps = () => {
        if (provider == VALUES.KEEPTRUCKIN) {
            return <KeepTruckinIntegration code={searchParams.get("code")}/>
        }
        if (provider == VALUES.ELD360) {
            return <EZELD365Integration />
        }
    }

    console.log(searchParams.get("type"))
   return (
        <Flex
            minH={'100vh'}
            paddingTop={10}
            justify={'center'}
            >
            <Stack spacing={8} w={'7xl'} py={10} px={6}>
                <Stack>
                    <Heading fontSize={'3xl'}>Connect your ELD</Heading>
                    <Text>Connect your ELD for your broker to track you hands-free!</Text>
                </Stack>
                <Box  rounded={'lg'}
                      boxShadow={'lg'}
                      p={8}>
                    {provider === null ?
                        (
                            <Box>
                            <Heading size={"md"}>
                        Choose your ELD Provider to get started
                    </Heading>
                    <Stack padding={5} spacing={10}>
                        <RadioGroup value={provider} onChange={navig}>
                            <Stack>
                                <Radio value={VALUES.KEEPTRUCKIN}>Motive(KeepTruckin)</Radio>
                                <Radio value={2}>Samsara</Radio>
                                <Radio value={3}>BigRoads</Radio>
                                <Radio value={VALUES.ELD360}>EZ-ELD ELD360</Radio>
                            </Stack>
                        </RadioGroup>
                        <Text>
                            Please reach out to us at support@livetrucks.io if you don't see your ELD provider listed
                        </Text>
                    </Stack>
                            </Box>) : getProviderSteps()}
                </Box>
            </Stack>
        </Flex>
   );

    return (
        <Box> Hi </Box>
    )
}


export const EZELD365Integration = (props) => {
    const {code} = props;
    const {user} = useBrokerAuth();
    const toast = useToast()
    const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
        initialStep: 0,
    });


    return (
        <Formik
            initialValues={{ email: '', password: ''}}
            onSubmit={async (values, actions) => {
                try {
                    await amplitude.logEvent('eld', values)
                } catch (e) {
                    actions.setSubmitting(false)
                    return
                } finally {
                    toast({
                        title: 'ELD Connection was connected',
                        description: "We will reach back out with next steps",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    actions.setSubmitting(false)
                }
            }}
        >
            {(props) => (
                <Form id="signup-form">
                    <Stack spacing={5} padding="20px">
                        <Field name='email'>
                            {({ field, form }) => (
                                <FormControl isInvalid={form.errors.email && form.touched.email}>
                                    <FormLabel htmlFor='email'>Email</FormLabel>
                                    <Input {...field} id='email' placeholder='hello@roserocket.com' />
                                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>

                        <Field name='password'>
                            {({ field, form }) => (
                                <FormControl isInvalid={form.errors.password && form.touched.password}>
                                    <FormLabel htmlFor='userName'>Password</FormLabel>
                                    <Input type={'password'}{...field} id='password' />
                                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Field>
                        <Button
                            mt={4}
                            colorScheme='teal'
                            isLoading={props.isSubmitting}
                            type='submit'
                        >
                            Submit
                        </Button>
                    </Stack>
                </Form>

            )}
        </Formik>
    );
}


export const KeepTruckinIntegration = (props) => {
    const {code} = props;
    const navigate = useNavigate();
    const {user} = useCarrierAuth();
    const [vehicles, setVehicles] = useState([])
    const [selectedVehicle, setSelectedVehicle] = useState(null)
    const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
        initialStep: 0,
    });

    console.log(selectedVehicle)
    useEffect(() => {
        fetcher(`/carrier/kt/oauth/${code}`).finally((res) => {
            fetcher(`/carrier/kt/vehicles`).then((vehicles) => setVehicles(vehicles.vehicles))
        })
    }, [code])

    const ConnectYourTruck = () => {
        return (
            <Stack padding={10} spacing={10} maxW="500px" align="left">
            <Heading size={"md"}>
            Select the truck you want to be linked to
        </Heading>
        <Select value={selectedVehicle} placeholder="Select your vehicle" onChange={(event) => setSelectedVehicle(event.target.value)}>
            {vehicles.map(({vehicle}: vehicle) => {
                return <option value={vehicle.id}>{vehicle.number}({vehicle.id})</option>
            })}
        </Select>
            </Stack>
    )
    }
    const Confirm = () => {
        return (
            <Stack padding={10} spacing={10} maxW="500px" align="center">
                <Heading size={"md"}>
                   Please confirm that you would like to link the following accounts
                </Heading>
                <HStack>
                    <Box boxShadow={"md"} padding={5} size={"md"}>
                        <Stack>
                            <Heading size={"sm"}>
                                Phone number
                            </Heading>
                            <Text>
                                {user.phone_number}
                            </Text>
                        </Stack>
                    </Box>
                    +
                    <Box boxShadow={"md"} padding={5} size={"md"}>
                        <Stack>
                            <Heading size={"sm"}>
                               Truck number
                            </Heading>
                            <Text>
                                {selectedVehicle}
                            </Text>
                        </Stack>
                    </Box>
                </HStack>
            </Stack>
        )
    }
    const steps = [
        { label: 'Connect your truck', content: <ConnectYourTruck /> },
        { label: 'Confirm', content: <Confirm /> },
    ];

    return (
        <>
                    <Steps activeStep={activeStep}>
                        {steps.map(({ label, content }) => (
                            <Step label={label} key={label}>
                                {content}
                            </Step>
                        ))}
                    </Steps>
                    {activeStep === steps.length ? (
                        <Flex p={6}>
                            <Text>
                                You are all done! Feel free to close this window.
                            </Text>
                        </Flex>
                    ) : (
                        <Flex width="100%" justify="flex-end">
                            <Button
                                isDisabled={activeStep === 0}
                                mr={4}
                                onClick={prevStep}
                                size="sm"
                                variant="ghost"
                            >
                                Prev
                            </Button>
                            <Button size="sm" onClick={() => {
                                if(activeStep === steps.length - 1) {
                                    post('/carrier/kt/update_connection', {
                                        id: Number(selectedVehicle),
                                    }).then((res) => nextStep())
                                } else {
                                    nextStep()
                                }
                            }}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Flex>
                    )}
                </>
    );
}
