import React, {useEffect, useMemo} from 'react'
import {GoogleMap, Polyline, useJsApiLoader, Marker} from '@react-google-maps/api';
import {Client} from "@googlemaps/google-maps-services-js";
import {customFloor} from "../../util/round";
import {fetcher} from "../../util/requests/axios";
import {TrackingRecord} from "../../models";
import {FaTruckMoving} from "react-icons/all";
import axios from "axios";
const google = window.google;

const svgMarker = {
    path: "M34.572 5.534H12.3v4.373H5.789L0 15.696v8.971h3.202c.331 2.462 2.422 4.373 4.972 4.373s4.641-1.911 4.973-4.373h1.613 6.667c.33 2.462 2.422 4.373 4.971 4.373s4.643-1.911 4.974-4.373h3.201l-.001-19.133h0zM8.174 27.04c-1.676 0-3.039-1.363-3.039-3.041s1.363-3.039 3.039-3.039 3.039 1.363 3.039 3.039-1.364 3.041-3.039 3.041zm18.224 0c-1.674 0-3.037-1.363-3.037-3.041s1.363-3.039 3.037-3.039 3.039 1.363 3.039 3.039-1.363 3.041-3.039 3.041z",
    fillColor: "red",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 0.5,
    anchor: new google.maps.Point(15, 30)
};



const mapStyle = [
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#000000"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#000000"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 17
            }
        ]
    }
]


const containerStyle = {
    width: '100%',
    height: '50vh'
};

const center = {
    lat: 37.773972,
    lng:  -122.431297
};


function getRandom(arr, n) {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result.sort((a, b) => a.recorded_at - b.recorded_at);;
}

const locMapper = () => {

}
const key = "AIzaSyCfvyrd_c5wb9BWXN8T99RwnvOZposRM-M"
function MyComponent(props) {
    const [completedCords, setcompletedCords] = React.useState(null);
    const [remainingCords, setRemainingCords] = React.useState(null);
    const [fetchingRecords, setFetchingRecords] = React.useState(false);
    const [doneLoading, setDoneLoading] = React.useState(false);
    const {origin, locations, destination, shipment} = props;
    const [reducedLocations, setReducedLocations] = React.useState([])
    const client = new Client({});

    const TruckMarker = () => {
        if (reducedLocations == null || reducedLocations.length == 0) {
            return null
        }

        const lastLocation = reducedLocations[reducedLocations.length - 1]
        return <Marker position={{
            lat: lastLocation.location.lat,
            lng: lastLocation.location.long,
        }} icon={svgMarker}/>
    }

    // const TruckMarker = useMemo(() => {
    //     return
    // }, [destination])

    useEffect(() => {
        const func = async () => {
            const fetched = await fetcher(`unauth/sampled_tracking_records/${shipment.id}`, true)
            const reducedLocations = fetched.map((loc) => new TrackingRecord(loc))
            setReducedLocations(reducedLocations)
        }

        func()
    }, [])

    useEffect(() => {
        const func = async () => {
            if (reducedLocations.length !== 0) {
                let locs = reducedLocations.map((loc) => {
                    return [loc.location.lat, loc.location.long]
                })

                const chunkSize = 100;
                let finalRes = []
                for (let i = 0; i < locs.length; i += chunkSize) {
                    const chunk = locs.slice(i, i + chunkSize);
                    const path = `${chunk.map((loc) => {
                       return `${loc[0]}, ${loc[1]}`
                    }).join('|')}`
                    const res = await axios.get(`https://roads.googleapis.com/v1/snapToRoads?path=${path}&key=${key}`)
                    // const res = await roads.snapToRoads({
                    //     params: {
                    //         key,
                    //         path: chunk,
                    //         interpolate: true,
                    //     }
                    // })
                    console.log(res)
                    const mapped = res.data.snappedPoints.map((point) => {
                        return {
                            lat: point.location.latitude,
                            lng: point.location.longitude,
                        }
                    })
                    finalRes = finalRes.concat(mapped)
                }


                setcompletedCords(finalRes)
                setDoneLoading(true)
            }

            const last = reducedLocations.length > 0 ? reducedLocations[reducedLocations.length - 1].location : origin;
            const directionsService = new google.maps.DirectionsService();
            const res = await directionsService.route({
                origin: {lat: last.lat, lng: last.long},
                destination: {lat: destination.lat, lng: destination.long},
                travelMode: google.maps.TravelMode.DRIVING,
            })
            setRemainingCords(google.maps.geometry.encoding.decodePath(res.routes[0].overview_polyline))
        }

        func()
        // } else {
        //     const remainingRoute = `${locMapper(origin)};${locMapper(destination)}`
        //     fetch('' +
        //         'https://api.mapbox.com/directions/v5/mapbox/driving/' +
        //         `${remainingRoute}?` +
        //         'geometries=geojson&' +
        //         'access_token=pk.eyJ1IjoidGFudWo5OCIsImEiOiJjbDA2YXY4ZTQyNjEyM2pzMHRnYnZ4MXk5In0.h8b-3V8ej64d2xNGQCdfQg').then(
        //         (res) => res.json()).then((results) => {
        //         setRemainingCords(results.routes[0].geometry)
        //     })
        // }
    }, [reducedLocations, origin, destination])


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: key,
    })

    const [map, setMap] = React.useState(null)
    console.log(reducedLocations)

    const onLoad = React.useCallback(function callback(map) {
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={4.5}
            onLoad={onLoad}
            onUnmount={onUnmount}
            // options={{
            //     styles: mapStyle
            // }}
        >
            {
                doneLoading ?
                    <>
                <Polyline
                    options={{
                        strokeColor: 'blue',
                        strokeWeight: 2,
                    }}
                    path={completedCords}
                />
              </>: null
            }
            <Polyline
                options={{
                    strokeColor: 'gray',
                    strokeWeight: 2,
                }}
                path={remainingCords}
            />
            <TruckMarker />
            <Marker color="red"  position={{lat: destination.lat, lng: destination.long}}/>
            { /* Child components, such as markers, info windows, etc. */ }
            <></>
        </GoogleMap>
    ) : <></>
}

export default React.memo(MyComponent)
