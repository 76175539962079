import axios from "axios";


const roseRocket = axios.create({
    baseURL: "https://roserocket.com/api/v1/",
})

export const LoginRoseRocket = (email, password) => {
    roseRocket.post('sessions', {
        email,
        password,
    }).then((res) => console.log(res.data))
}

export const CreateOrgApp = () => {

}
