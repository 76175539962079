import './App.css';
import {CallToActionWithIllustration} from "./marketing/landing";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {Broker} from "./broker";
import NavBar from "./nav-bar";
import React, {createContext, useEffect} from "react";
import {ShipmentDetailsPage} from "./broker/shipment-details";
import {BrokerAuthProvider, RequireBrokerAuth} from "./util/auth/broker-auth";
import {LoginPage} from "./broker/sign-in";
import {CarrierLocationCallback} from "./carrier/location";
import {Signup, SignupDetails} from "./marketing/signup";
import {Refer} from "./marketing/refer";
import {Support} from "./marketing/support";
import amplitude from 'amplitude-js';
import {SharedShipmentView} from "./shared/shared-shipment";
import {ConnectELD} from "./carrier/connect-eld";
import {CarrierAuthProvider, RequireCarrierAuth} from "./util/auth/carrier-auth";
import {CarrierLogin} from "./carrier/sign-in";
import {ConnectTMS} from "./broker/tms";
import {ChooseTrackingMethod} from "./carrier/tracking-method";
import {RequireAdminAuth} from "./util/auth/admin-auth";
import {AdminCreateModal} from "./admin/rate-con";
import {CreateShipmentPage} from "./broker/create-shipment";

amplitude.getInstance().init("cb7bd433e08ea32c21262f891bde126e");

function App() {
  return (
      <BrokerAuthProvider>
          <CarrierAuthProvider>
      <div>
        <NavBar />
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<CallToActionWithIllustration />} />
        <Route path="/signup" element={<SignupDetails />} />
        <Route path="/refer" element={<Refer />} />
        <Route path="/support" element={<Support />} />
        <Route path="share/shipments/:id" element={<SharedShipmentView />} />
        <Route path="broker/shipments/:id" element={
            <RequireBrokerAuth>
                <ShipmentDetailsPage />
            </RequireBrokerAuth>
                } />
        <Route path="broker/create" element={
            <RequireBrokerAuth>
                <CreateShipmentPage />
            </RequireBrokerAuth>
        } />
        <Route path="broker/tms" element={
            // <RequireBrokerAuth>
                <ConnectTMS />
            // </RequireBrokerAuth>
        } />
      <Route path="broker" element={
            <RequireBrokerAuth>
          <Broker />
            </RequireBrokerAuth>
                }
      />
        <Route path="login" element={<LoginPage />} />
        <Route path="carrier/login" element={<CarrierLogin />} />
        <Route path="carrier/location/:loadId" element={<CarrierLocationCallback />} />
        <Route path="carrier/connect" element={
            <RequireCarrierAuth>
            <ConnectELD />
            </RequireCarrierAuth>
        } />
        <Route path="carrier/connect-dispatcher" element={
                <ConnectELD />
        } />
        <Route path="carrier/select-method/:loadId" element={<ChooseTrackingMethod /> } />
        <Route path="admin/create-load" element={
            <RequireAdminAuth>
                <AdminCreateModal />
            </RequireAdminAuth>
        } />
        <Route path="admin/carrier-select/:loadId" element={
            <RequireAdminAuth>
                <ChooseTrackingMethod />
            </RequireAdminAuth>
        } />
        <Route path="admin/edit-load" element={
            <RequireAdminAuth>
                <AdminCreateModal />
            </RequireAdminAuth>
        } />
        {/*<Route path={"admin/create-load"} element={*/}
        {/*    <RequireAdminAuth>*/}
        {/*        <AdminCreateModal />*/}
        {/*    </RequireAdminAuth>*/}
        {/*} />*/}
    </Routes>
  </BrowserRouter>
      </div>
          </CarrierAuthProvider>
      </BrokerAuthProvider>
  );
}

export default App;
