import '@fontsource/archivo/400.css'

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ChakraProvider, extendTheme} from '@chakra-ui/react'
import {Helmet} from "react-helmet";
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import { IntercomProvider} from 'react-use-intercom';
import {H} from "highlight.run";
import posthog from 'posthog-js'


const colors = {
    brand: {
        50: "#ecefff",
        100: "#cbceeb",
        200: "#a9aed6",
        300: "#888ec5",
        400: "#666db3",
        500: "#4d5499",
        600: "#3c4178",
        700: "#2a2f57",
        800: "#181c37",
        900: "#080819",
        1000: "#4287f5",
        1200: "#0070f3"
    }
};

const fonts = {
    heading: `'Archivo', sans-serif`,
        body: `'Archivo', sans-serif`,
};

const config = {
    initialColorMode: "light",
    useSystemColorMode: false,
};

const components = {
        Steps,
};

const theme = extendTheme({ colors, config, components, fonts });

H.init('2d1013gr');
posthog.init('phc_1ewpaFHHSfqUodT6TfGPRg481ATsaOfV3oFGy14Wgq7', { api_host: 'https://app.posthog.com' })

ReactDOM.render(
  <React.StrictMode>

      <ChakraProvider theme={theme}>
          <Helmet>

              <meta charSet="utf-8" />
              <title>Livetrucks</title>
              <link rel="canonical" href="https://livetrucks,io" />
              <link rel="icon" type="image/png" href="favicon.png" sizes="16x16" />
          </Helmet>
    <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
