import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
    Box,
    Button, Collapse,
    Container,
    Flex,
    Heading, HStack, Link, ListItem,
    Radio,
    RadioGroup, Select, Spinner,
    Stack, Tag,
    Text, UnorderedList,
    useColorModeValue, useRadio, useRadioGroup, VStack
} from "@chakra-ui/react";
import {Step, Steps, useSteps} from "chakra-ui-steps";
import React, {useCallback, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useCarrierAuth} from "../util/auth/carrier-auth";
import {BASE_URL, fetcher, fetcherWithoutData, post} from "../util/requests/carrier-axios";
import amplitude from "amplitude-js";
import {IoIosRadioButtonOff, IoIosRadioButtonOn} from "react-icons/all";
import {iOS} from "../util/user-agent";
import posthog from "posthog-js";
import {EVENT_NAMES} from "../constants";
const content = (
    <Flex py={4}>
        Hi
    </Flex>
);

const VALUES = {
    KEEPTRUCKIN: 1,
}

const TrackingMethod = {
    LIVETRUCKS_APP: 'LIVETRUCKS_APP',
    TRUCKER_TOOLS: 'TRUCKER_TOOLS',
    ELD: 'ELD',
    CHECK_TEXTS: 'CHECK_TEXTS',
    CHECK_CALLS: 'CHECK_CALLS',
    INBOUND_CHECK_CALLS: 'INBOUND_CHECK_CALLS'
};

const TRACKING_METHODS = {
    [TrackingMethod.LIVETRUCKS_APP]: <HStack spacing={2}><Text>LiveTrucks App </Text> <Tag bg={'teal.300'}>Preferred</Tag></HStack>,
    [TrackingMethod.ELD]: <HStack pacing={2}><Text>Connect your ELD</Text> <Tag bg={'teal.200'} >100$ Bonus</Tag></HStack>,
    [TrackingMethod.TRUCKER_TOOLS]: 'TruckerTools',
    [TrackingMethod.CHECK_TEXTS]: 'Automated texts',
    [TrackingMethod.CHECK_CALLS]: 'Check calls',
    [TrackingMethod.INBOUND_CHECK_CALLS]: 'Call in periodically to update your location'
}

const MethodToServer = {
    [TrackingMethod.TRUCKER_TOOLS]: 0,
    [TrackingMethod.LIVETRUCKS_APP]: 1,
    [TrackingMethod.CHECK_TEXTS]: 2,
    [TrackingMethod.CHECK_CALLS]: 3,
    [TrackingMethod.ELD]: 4,
    [TrackingMethod.INBOUND_CHECK_CALLS]: 5,
}

export const ChooseTrackingMethodSinglePage = () => {
    return (
        <Accordion>
            <AccordionItem>
                {({ isExpanded }) => {
                   return <React.Fragment>
                    <AccordionButton>
                        <HStack spacing={4}>
                        <Radio isChecked={isExpanded}/>
                        <Box flex='1' textAlign='left'>
                            Section 1 title
                        </Box>
                        </HStack>
                    </AccordionButton>
                <AccordionPanel pb={4}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo consequat.
                </AccordionPanel>
                    </React.Fragment>
                }}
            </AccordionItem>
            <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                            Section 2 title
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo consequat.
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )
}

export const ChooseTrackingMethod = () => {
    const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
        initialStep: 0,
    });


    const [method, setMethod] = useState(null);
    const {loadId} = useParams();
    const {pathname} = useLocation();

    const posthogCapture = useCallback((eventName, properties) => {
        if (pathname.includes('admin')) {
            return
        }

        posthog.capture(eventName, properties)
    }, [pathname])

    useEffect(() => {
        posthogCapture(EVENT_NAMES.CARRIER_TRACKING_PAGE_LOAD, { loadId })
    }, [loadId])


    const onChange = (newMethod) => {
        setMethod(newMethod)
        nextStep()
        amplitude.getInstance().logEvent("carrier-onboarding", {
            provider: newMethod,
            load_id: loadId,
        });
        posthogCapture(EVENT_NAMES.CARRIER_CHOOSE_METHOD_STEP_ONE, {loadId})
        return post(`${BASE_URL}/unauth/tracking_config`, {
            provider: MethodToServer[newMethod],
            load_id: new Number(loadId),
        }, true)
    }

    const onSubmit = () => {
        amplitude.getInstance().logEvent("carrier-onboarding", {
            provider: method,
            load_id: loadId,
        });
        posthog.capture(EVENT_NAMES.CARRIER_CHOOSE_METHOD_FINISH, {loadId})
        return post(`${BASE_URL}/unauth/tracking_config`, {
            provider: MethodToServer[method],
            load_id: new Number(loadId),
        }, true)
    }

    const ChooseMethod = () => {
        return <Stack p={{base: 2, md: 10}}>
        <Stack spacing={10}>
            <RadioGroup value={method} textAlign={"left"} onChange={onChange}>
                <Stack spacing={3}>
                    {Object.entries(TRACKING_METHODS).map(([method, desc]) => {
                        return <Radio value={method} textAlign={"left"}>
                            {desc}
                        </Radio>
                    })}
                </Stack>
            </RadioGroup>
            <Text>
                Please reach out to us at support@livetrucks.io if you don't see your preferred method listed.
            </Text>
        </Stack>
        </Stack>
    }
    const APP_COMPONENT = () => {
        const getLink = () => {
            if (iOS()) {
                return "https://apps.apple.com/us/app/livetrucks-carrier/id1630010131"
            }

            return "https://play.google.com/store/apps/details?id=com.livetrucks_tracking"
        }

        useEffect(() => {
           const win =  window.open(getLink(), "_blank");
            if (win != null) {
                win.focus();
            }
        }, [])
        return (
            <Stack align={"flex-start"} textAlign={"left"}>
                <Text>
                    1. Download our LiveTrucks app
                    <Link target="_blank" href={getLink()} color='teal.500'> Click here to download </Link>
                </Text>
                <Text>
                    2. Verify your phone number
                </Text>
                <Text>
                    3. Click start location updates button
                </Text>
            </Stack>
        )
    }

    const TRUCKER_TOOLS = () => {
        const [TTURL, setTTURL] = useState('')
        const [isLoading, setIsLoading] = useState(true)
        const [error, setError] = useState(null)
        useEffect(() => {
            const run = async () => {
                try {
                    setError(null)
                    const res = await fetcher(`/unauth/trucker_tools_details/${loadId}`, true)
                    setTTURL(res.driverBranchLink)
                    setIsLoading(false)
                    const win =  window.open(res.driverBranchLink, "_blank");
                    if (win != null) {
                        win.focus();
                    }
                } catch (e) {
                    setIsLoading(false)
                    setError(e)
                }
            }

            run()
        }, [])
        if (isLoading) {
            return <Spinner />
        }

        if(error != null) {
            return (
                <Stack align={"flex-start"} textAlign={"left"}>
                    <Text>
                       Sorry, something went wrong. We will reach out to you with next steps within next 15 minutes
                    </Text>
                </Stack>
            )
        }

        return (
                    <Stack align={"flex-start"} textAlign={"left"}>
                        <Text>
                            1. Please accept TruckerTools request here
                            <Link target="_blank" href={TTURL} color='teal.500'> Click here to accept </Link>
                        </Text>
                    </Stack>
        )
    }
    const Instructions = () => {
        const instructions = {
            [TrackingMethod.LIVETRUCKS_APP]: <APP_COMPONENT />,
            [TrackingMethod.CHECK_TEXTS]: (
                <Stack>
                    <Text>
                        We will send you a text every few hours with a link that you should be able to click to register your realtime location.
                    </Text>
                </Stack>
            ),
            [TrackingMethod.CHECK_CALLS]: (
                <Stack>
                    <Text>
                        We will call you every few hours to get up to date location. Look forward to a call from (424) 237-3962
                    </Text>
                </Stack>
            ),
            [TrackingMethod.TRUCKER_TOOLS]: <TRUCKER_TOOLS />,
            [TrackingMethod.ELD]: (
                <Stack align={"flex-start"} textAlign={"left"}>
                    <Text as={"u"}>
                        <Link target="_blank" href={"/carrier/connect"}> 1. Navigate to ELD connection page </Link>

                    </Text>
                        <Text>
                        2. Finish the connection
                        </Text>
                        <Text>
                        3. Voila!
                        </Text>
                </Stack>
            ),
            [TrackingMethod.INBOUND_CHECK_CALLS]: (
                <Stack>
                    <Text>
                        Please call us at +16124211852 every few hours to update your location
                    </Text>
                </Stack>
            ),

        }
        return <Stack p={{base: 2, md: 10}}>

            {instructions[method]}
        </Stack>
    }
    const steps = [
        {label: 'Select tracking method', content: <ChooseMethod />},
        {label: 'Method instructions', content: <Instructions />},
    ]

    return (
        <Container py={6} w="100%" maxW={{base: '95vw', lg: '80vw'}} alignContent={"center"} fontFamily="body">
            <Stack spacing={8} maxW={{base: '90vw', lg: '80vw'}} py={{base: 2, md: 10}}>
                <Stack>
                    <Heading fontSize={'3xl'} display={{base: 'none', md: 'block'}}>Tracking Preferences</Heading>
                    <Heading fontSize={'m'}>Please select your preferred tracking method here for your broker</Heading>
                </Stack>
                <Box  rounded={'lg'}
                      boxShadow={'lg'}
                      p={{base: 2, md: 8}}>

                                    <Steps activeStep={activeStep}>
                                        {steps.map(({ label, content }) => (
                                            <Step label={label} key={label}>
                                                {content}
                                            </Step>
                                        ))}
                                    </Steps>
                                    {activeStep === steps.length ? (
                                        <Flex p={6}>
                                            <Text>
                                                You are all done! Feel free to close this window.
                                            </Text>
                                        </Flex>
                                    ) : (
                                        <Flex width="100%" justify="flex-end">
                                            <Button
                                                isDisabled={activeStep === 0}
                                                mr={4}
                                                onClick={prevStep}
                                                size="sm"
                                                variant="ghost"
                                            >
                                                Prev
                                            </Button>
                                            <Button size="sm" onClick={() => {
                                                onSubmit().then((res) => nextStep())
                                            }}>
                                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                            </Button>
                                        </Flex>
                                    )}

                </Box>
            </Stack>
        </Container>
    );
}

//
// export const KeepTruckinIntegration = (props) => {
//     const {code} = props;
//     const navigate = useNavigate();
//     const {user} = useCarrierAuth();
//     const [vehicles, setVehicles] = useState([])
//     const [selectedVehicle, setSelectedVehicle] = useState(null)
//     const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
//         initialStep: 0,
//     });
//
//     console.log(selectedVehicle)
//     useEffect(() => {
//         fetcher(`/carrier/kt/oauth/${code}`).finally((res) => {
//             fetcher(`/carrier/kt/vehicles`).then((vehicles) => setVehicles(vehicles.vehicles))
//         })
//     }, [code])
//
//     const ConnectYourTruck = () => {
//         return (
//             <Stack padding={10} spacing={10} maxW="500px" align="left">
//                 <Heading size={"md"}>
//                     Select the truck you want to be linked to
//                 </Heading>
//                 <Select value={selectedVehicle} placeholder="Select your vehicle" onChange={(event) => setSelectedVehicle(event.target.value)}>
//                     {vehicles.map(({vehicle}: vehicle) => {
//                         return <option value={vehicle.id}>{vehicle.number}({vehicle.id})</option>
//                     })}
//                 </Select>
//             </Stack>
//         )
//     }
//     const Confirm = () => {
//         return (
//             <Stack padding={10} spacing={10} maxW="500px" align="center">
//                 <Heading size={"md"}>
//                     Please confirm that you would like to link the following accounts
//                 </Heading>
//                 <HStack>
//                     <Box boxShadow={"md"} padding={5} size={"md"}>
//                         <Stack>
//                             <Heading size={"sm"}>
//                                 Phone number
//                             </Heading>
//                             <Text>
//                                 {user.phone_number}
//                             </Text>
//                         </Stack>
//                     </Box>
//                     +
//                     <Box boxShadow={"md"} padding={5} size={"md"}>
//                         <Stack>
//                             <Heading size={"sm"}>
//                                 Truck number
//                             </Heading>
//                             <Text>
//                                 {selectedVehicle}
//                             </Text>
//                         </Stack>
//                     </Box>
//                 </HStack>
//             </Stack>
//         )
//     }
//     const steps = [
//         { label: 'Connect your truck', content: <ConnectYourTruck /> },
//         { label: 'Confirm', content: <Confirm /> },
//     ];
//
//     return (
//         <>
//             <Steps activeStep={activeStep}>
//                 {steps.map(({ label, content }) => (
//                     <Step label={label} key={label}>
//                         {content}
//                     </Step>
//                 ))}
//             </Steps>
//             {activeStep === steps.length ? (
//                 <Flex p={6}>
//                     <Text>
//                         You are all done! Feel free to close this window.
//                     </Text>
//                 </Flex>
//             ) : (
//                 <Flex width="100%" justify="flex-end">
//                     <Button
//                         isDisabled={activeStep === 0}
//                         mr={4}
//                         onClick={prevStep}
//                         size="sm"
//                         variant="ghost"
//                     >
//                         Prev
//                     </Button>
//                     <Button size="sm" onClick={() => {
//                         if(activeStep === steps.length - 1) {
//                             post('/carrier/kt/update_connection', {
//                                 id: Number(selectedVehicle),
//                             }).then((res) => nextStep())
//                         } else {
//                             nextStep()
//                         }
//                     }}>
//                         {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
//                     </Button>
//                 </Flex>
//             )}
//         </>
//     );
// }
